import React from "react";
import { Container } from "react-bootstrap";
import Hotpairs from "../Hotpairs";
import Dashfront from "./Dashfront";

function Dashtableview() {
  return (
    <section className="dashview my-3">
      <Container fluid>
        <Hotpairs />
        <Dashfront/>  
      </Container>
    </section>
  );
}

export default Dashtableview;
