import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "store/actions";
import * as API from "helpers/backend_helper";

import toastr from "toastr";

import { updateToken } from "helpers/api_helper";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(API.postLogin, user);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.apiError(response.message));
    }
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    yield put(ACTIONS.registerUserSuccessful(response));
    // if (response.type === "loginOTP") {
    //   navigate("/otp");
    // } else {
    //   navigate("/otp-verify");
    // }
    navigate("/");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* loginWithOtp({ payload: { user, navigate } }) {
  try {
    const response = yield call(API.postLoginWithOtp, user);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.apiError(response.message));
    }
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    navigate("/dashboard");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* currentUser() {
  try {
    const response = yield call(API.getCurrentUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.currentUserFail(response.message));
    }

    yield put(ACTIONS.currentUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");
    yield put(ACTIONS.logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.LOGIN_OTP, loginWithOtp);
  yield takeEvery(TYPES.GET_CURRENT_USER, currentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
}

export default authSaga;
