import React from "react";
import Tradenew from "Pages/Profile/Tradenew";
import Headerlogin from "Component/Header/Headerlogin";

export default function Tradetheme() {
  return (
    <>
      <Headerlogin heading="Trade" />
      <Tradenew />
    </>
  );
}
