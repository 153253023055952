import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Transacsubmitmmod(props) {
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="trasnsaction_submit"
    >
      <Modal.Body>
        <div className="trassaction_mod_inner position-relative">
          <a href="javascript:void(0)" className="modal_close" onClick={props.onhide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.758 17.2431L12.001 12.0001M17.244 6.75708L12 12.0001M12 12.0001L6.758 6.75708M12.001 12.0001L17.244 17.2431"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>

          <div className="submit_inner">
               {/* <div className="chain_transaction_name">
                    <div className="modalchain_img">
                       <img src="/images/ethgreen.png" alt="" className="img-fluid"/>
                    </div>
                    <h4>Ethereum</h4>
               </div> */}
              <div className="hurry_img">
                <img src="/images/hurry.gif" alt="" className="img-fluid"/>
              </div>
              <h3>Transaction Submitted</h3>
              {/* <Button className="btn_common btn-done w-100">Done</Button> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
