import React, { useState } from "react";
import Tradehistorytable from "./tradehistorytable";
import Trasactionhistory from "./transactionhistory";

const Dashtabletabs = () => {
  const [state, setState] = useState({ tab: "tab1" });
  return (
    <section className="dashtable mt-5">
      <div className="tab-frame dashtable-frame">
        <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Trade History</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">My Trade</label>
        </div>

        {state.tab == "tab1" && (
          <div className="common_tabbing_content">
            <Tradehistorytable />
          </div>
        )}

        {state.tab == "tab2" && (
          <div className="common_tabbing_content">
            <Trasactionhistory />
          </div>
        )}
      </div>
    </section>
  );
};

export default Dashtabletabs;
