import React from "react";
import { Col, Button } from "react-bootstrap";

export default function BlogCard({ data, index, detailsclick, enableActions, handleFormPopup, removeBlog }) {
  return (
    <Col lg={3} md={6} sm={12} key={index}>
      <div className="post_Cards">
        <div
          className="postcard_img mb-2"
          onClick={() => detailsclick(data._id)}
        >
          <img
            src={data.blogImage?.link || "/images/memone.png"}
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="postCard_content text-start">
          <div className="with_btn d-flex align-items-center justify-content-between">
            <h4
              className="text-truncate w-75"
              onClick={() => detailsclick(data._id)}
            >
              {data.title}
            </h4>
            {enableActions && (
              <div className="btn_two d-flex gap-3 align-items-center">
                <Button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 32 32"
                    onClick={() => handleFormPopup(data)}
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m30 7l-5-5L5 22l-2 7l7-2Zm-9-1l5 5ZM5 22l5 5Z"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="22"
                    viewBox="0 0 1024 1024"
                    onClick={()=> removeBlog(data._id)}
                  >
                    <path
                      fill="#EA3943"
                      d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                    />
                  </svg>
                </Button>
              </div>
            )}
          </div>

          <p>{data.content}</p>
        </div>
      </div>
    </Col>
  );
}
