import React from "react";
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Trasactionhistory = () => {
  return (
    <section className="Opendertable-table">
      <Table responsive>
        <thead>
          <tr>
            <th>Time</th>
            <th>Symbol</th>
            <th>Type</th>
            <th>Side</th>
            <th>Price</th>
            <th>Amount</th>
            <th>
              <UncontrolledDropdown>
                <DropdownToggle caret>Status</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Another Action</DropdownItem>
                  <DropdownItem>Another Action</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6].map(() => {
            return (
              <tr>
                <td>2022-07-22 11:20:00</td>
                <td className="trde_symbol">
                  <p>
                    <strong>BNBUSD</strong>
                  </p>
                  <p>Perpetual</p>
                </td>
                <td>Limit</td>
                <td>
                  <p className="col-red">Sell</p>
                </td>

                <td>---</td>
                <td>235.32 USDT</td>
                <td>
                  <p className="col-green">Confirm</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export default Trasactionhistory;
