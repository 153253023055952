import React from "react";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

function ClickButton(props) {
  const { btnText, className, disabled, onClick } = props;
  return (
    <>
      <Button
        className={`${className ? className : "done-btn mt-3"}`}
        type="button"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <div className="d-flex align-center justify-center submit-btn">
          {disabled && (
            <div className="spinner">
              <Spinner color="primary" size="sm" />
            </div>
          )}

          {btnText}
        </div>
      </Button>
    </>
  );
}

export default ClickButton;
