import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import Botviewmod from "Component/Modals/Botviewmod";
import { getBotRequests, setRequestDetail } from "store/actions";
import { useNavigate } from "react-router-dom";

const Botinfo = ({ funds }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, bots } = useSelector((state) => state.Bot);
  const [botviewmod, setBotviewmod] = useState({
    isOpen: false,
    detail: null,
  });

  useEffect(() => {
    if (funds.selectedToken) {
      dispatch(
        getBotRequests({
          tokenId: funds.selectedToken._id,
          limit: 20,
        })
      );
    }
  }, [funds.selectedToken]);

  const handleEdit = (data) => {
    dispatch(setRequestDetail(data));
    navigate("/bot?type=edit");
  };

  const handlePopup = (data) => {
    setBotviewmod((pre) => ({
      ...pre,
      isOpen: !pre.isOpen,
      detail: data || null,
    }));
  };

  return (
    <>
      <Botviewmod show={botviewmod.isOpen} onhide={handlePopup} detail={botviewmod.detail} funds={funds}/>
      <section className="botSection_info">
        <div className="header_right_bot d-flex align-items-center justify-content-between pb-2 px-3">
          <h6>Token</h6>
          <h6>Quantity</h6>
          <h6>Action</h6>
        </div>
        <ul>
          {!funds.selectedToken ? (
            <p className="text-center text-white mt-3">
              Please select token first
            </p>
          ) : !bots.length ? (
            <p className="text-center text-white mt-3">No Request Found!</p>
          ) : (
            bots.map((request) => {
              return (
                <li>
                  <div className="chain_preivew d-flex align-items-center justify-content-between">
                    <div className="chain_iconwith d-flex align-items-center">
                      <div className="chain_icon me-2">
                        <img
                          src={request.tokenId?.icon}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h6>{request.tokenId?.name}</h6>
                    </div>
                    <div className="quantity">
                      <p>{request.tokenAmount}</p>
                    </div>
                    <div>
                      {request.status === "pending" && (
                        <Button
                          className="view_btn mr-2"
                          onClick={() => handleEdit(request)}
                          disabled={loading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="15"
                            height="15"
                            x="0"
                            y="0"
                            viewBox="0 0 492.493 492"
                          >
                            <g>
                              <path
                                d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                                fill="#fff"
                                opacity="1"
                                data-original="#fff"
                              ></path>
                            </g>
                          </svg>
                        </Button>
                      )}

                      <Button
                        className="view_btn"
                        onClick={() => handlePopup(request)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="15"
                          height="15"
                          x="0"
                          y="0"
                          viewBox="0 0 511.999 511.999"
                          className=""
                        >
                          <g>
                            <path
                              d="M508.745 246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818 239.784 3.249 246.035a16.896 16.896 0 0 0 0 19.923c4.569 6.257 113.557 153.206 252.748 153.206s248.174-146.95 252.748-153.201a16.875 16.875 0 0 0 0-19.922zM255.997 385.406c-102.529 0-191.33-97.533-217.617-129.418 26.253-31.913 114.868-129.395 217.617-129.395 102.524 0 191.319 97.516 217.617 129.418-26.253 31.912-114.868 129.395-217.617 129.395z"
                              fill="#fff"
                              opacity="1"
                              data-original="#fff"
                              className=""
                            ></path>
                            <path
                              d="M255.997 154.725c-55.842 0-101.275 45.433-101.275 101.275s45.433 101.275 101.275 101.275S357.272 311.842 357.272 256s-45.433-101.275-101.275-101.275zm0 168.791c-37.23 0-67.516-30.287-67.516-67.516s30.287-67.516 67.516-67.516 67.516 30.287 67.516 67.516-30.286 67.516-67.516 67.516z"
                              fill="#fff"
                              opacity="1"
                              data-original="#fff"
                              className=""
                            ></path>
                          </g>
                        </svg>
                      </Button>
                    </div>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      </section>
    </>
  );
};

export default Botinfo;
