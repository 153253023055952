import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";

export { PrivateRoute };

function PrivateRoute({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { user: authUser } = useSelector(({ Login }) => Login);
    if (!authUser?.Token) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/sign-in" state={{ from: location }} />
    }

    // authorized so return child components
    return children;
}