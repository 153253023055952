import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  BIT_FINEX_SOCKET,
  WETH_TOKEN_ADDRESS,
  convertCryptoToCrypto,
} from "helpers/constants";
import Web3Intraction from "./web3Intraction";
var W3CWebSocket = require("websocket").w3cwebsocket;

export const FundsContext = React.createContext({
  web3Intraction: null,
  ethToUsd: 0,
  tokenValueInEth: 0,
  tokenBalance: 0,
  bitFinexValue: 0,
  selectedToken: null,
  selectToken: () => {},
  fetchBalance: () => {},
});

export const FundsProvider = ({ children }) => {
  const { settings } = useSelector((state) => state.Settings);
  const [ethToUsd, setethToUsd] = useState(0);
  const [tokenValueInEth, setTokenValueInEth] = useState(0);
  const [bitFinexValue, setBitFinexValue] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [selectedToken, setSelectedToken] = useState(null);
  const [web3Intraction, setWeb3Intraction] = useState(null);

  const getTokenValueToEth = useCallback(async () => {
    try {
      if (!web3Intraction || !selectedToken) return;
      let getValueInEth = await web3Intraction.getConverterPrice(
        {
          contractAddress:
            selectedToken.networkMode == "testnet"
              ? selectedToken?.testnetContractAddress
              : selectedToken?.contractAddress,
        },
        { contractAddress: WETH_TOKEN_ADDRESS },
        "1",
        selectedToken?.decimals
      );

      setTokenValueInEth(Number(getValueInEth).toFixed(18));
    } catch (error) {
      console.log(error, "<===get token value");
    }
  }, [web3Intraction, JSON.stringify(selectedToken)]);

  const fetchBalance = async (token, address) => {
    let tokenDetail = token || selectedToken;
    if (!address || !web3Intraction || !tokenDetail) return;
    try {
      let value = await web3Intraction.getTokenPrice(
        tokenDetail.networkMode == "testnet"
          ? tokenDetail?.testnetContractAddress
          : tokenDetail?.contractAddress,
        address
      );

      setTokenBalance(value);
    } catch (error) {
      setTokenBalance(0);
      console.log(error, "<====error");
    }
  };

  const selectToken = (token) => {
    setSelectedToken(token);
  };

  // if()

  useEffect(() => {
    getTokenValueToEth();
  }, [getTokenValueToEth]);

  useEffect(() => {
    if (!settings) return;
    convertCryptoToCrypto("ETH", "USD", (err, resdata) => {
      if (!err) {
        setethToUsd(resdata);
      }
    });



    let web3 = new Web3Intraction("ethereum", settings);
    // console.log(JSON.stringify(web3), "<====web3")
    setWeb3Intraction(web3);
  }, [settings]);

  useEffect(() => {
    if (!selectedToken) return;
    const client = new W3CWebSocket(BIT_FINEX_SOCKET);
    client.onmessage = async (msg) => {
      const resp = JSON.parse(msg.data.toString());
      if (resp[1] && resp[1].length == 10) {
        const currentPrice = resp[1][6];
        setBitFinexValue(currentPrice);
      }
    };
    let msg = JSON.stringify({
      event: "subscribe",
      channel: "ticker",
      symbol: `t${
        selectedToken &&
        selectedToken.bitFinexSymbol &&
        selectedToken?.bitFinexSymbol
      }`,
    });

    client.onopen = () => client.send(msg, "msg");
  }, [selectedToken]);

  const values = useMemo(
    () => ({
      ethToUsd,
      tokenValueInEth,
      web3Intraction,
      selectedToken,
      selectToken,
      fetchBalance,
      tokenBalance,
      bitFinexValue,
    }),
    [
      ethToUsd,
      tokenValueInEth,
      web3Intraction,
      selectedToken,
      tokenBalance,
      bitFinexValue,
      selectToken,
      fetchBalance,
    ]
  );

  return (
    <FundsContext.Provider value={values}>{children}</FundsContext.Provider>
  );
};
