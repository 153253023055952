import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import SwapTokensModal from "Component/Modals/SwapTokensModal";
import Transacsubmitmmod from "Component/Modals/Transacsubmitmmod";
import { getAllPairsListRequest } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "Component/CustomPagination";

const Pairpricechart = (props) => {
  const [filters,setFilters] = useState({
    page : 1,
    limit : 10
  })
  const [transactionSucModal, setTransactionSucModal] = useState(false);
  const [openbuymodal ,setopenbuymodal] = useState({
    show : false,
    rowData : null
  });
  const { loading, pairsList,totalPairTokens } = useSelector(({ Crypto }) => Crypto);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getAllPairsListRequest(filters));
  }, [filters]);

 const onClickBuyButton = (rowData) =>{
    setopenbuymodal({
      show : true,
      rowData : rowData
    })
 };

  const onCloseSwapHandler = () =>{
      setopenbuymodal({
        show : false,
        rowData : null
      })
  }

  const onPageChange = (page) =>{
    setFilters((prev)=>({...prev, page}))
  };
  const totalPages = Math.ceil(totalPairTokens / filters?.limit);

  return (
    <>
       <Transacsubmitmmod
        show={transactionSucModal}
        onhide={() => setTransactionSucModal(false)}
      />
      <SwapTokensModal 
        selectedPair = {openbuymodal?.rowData}
        show={openbuymodal.show} 
        onhide={()=> onCloseSwapHandler()}
        onFinish={(data)=>setTransactionSucModal(true)}
        />
      <section className="Opendertable-table pairprice_chart mt-3">
        <div className="shared_head d-flex justify-content-between align-items_center mb-3">
          <h3 className="card_heading">{props.heading}</h3>
        </div>

        <div className="pairshared_table">
        <div className="d-flex justify-content-center">
            {loading &&
                <Spinner style={{ color: "#fff" }} />
            }
        </div>
        {!loading && 
        <>
        <Table responsive>
            <thead>
              <tr>
                <th>Pair Name</th>
                <th>Last</th>
                <th>High</th>
                <th>Low</th>
                <th>Volume 24h</th>
                <th>Price USD</th>
                <th>Action</th>
              </tr>
            </thead>
            
            <tbody>
              {(pairsList || []).map((item,index) => {
                return (
                  <tr key={index}> 
                    <td>
                      <Link to="/trade-listing">
                        <div className="table_assets d-flex align-items-center">
                          <div className="table_assets_img me-2">
                          <img
                                src={`${item?.primaryToken?.icon}`}
                                alt=""
                                width="30px"
                                className="img-fluid"
                            />
                          </div>
                           
                          <div className="table_assets_img me-2">
                          
                            <img
                                src={`${item?.secondryToken?.icon}`}
                                width="30px"
                                alt=""
                                className="img-fluid"
                            />
                          </div>
                          
                          <div className="table_assets_content">
                            <p>
                              <strong>{item?.primaryToken?.symbol}/{item?.secondryToken?.symbol}</strong>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>

                    <td>
                      <Button className="buy_themebtn" type="button" onClick={()=> onClickBuyButton(item)}>Buy</Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
           <div className="d-flex justify-content-end">
            <CustomPagination
              currentPage={filters?.page}
              totalPages={totalPages}
              onPageChange={(page)=>onPageChange(page)}
              maxPagesToShow={5} 
            />
           </div>
           </>
          }
        </div>
      </section>
    </>
  );
};

export default Pairpricechart;
