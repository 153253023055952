import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import toastr from "toastr";
import {
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

///helper
import useWallet from "Hooks/useWallet";

///store
import {
  editBotRequest,
  getTradeTokenRequest,
  makeBotRequest,
} from "store/actions";

//Component
import BotForm from "Component/Bot/BotForm";
import Web3Intraction from "utils/web3Intraction1";

const Bot = ({ funds }) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("type");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wallet = useWallet();

  //selector
  const { user } = useSelector((state) => state.Login);
  const { loading, orderDetails } = useSelector((state) => state.Bot);
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const { settings } = useSelector((state) => state.Settings);
  const [fields, setFields] = useState({
    position: "long",
    tokenAmount: 0,
    stopLoss: 0,
    targetPrice: 0,
    buyPrice: 0,
    leverage: 1,
    collateral: "",
    collateralTokens: 0,
    currentTicker: "",
    userId: "",
    tokenId: "",
    type: "GTT",
    status: "pending",
    balance: "",
  });
  const [balance, setBalance] = useState(0);
  const [botLoading, setBotLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getTradeTokenRequest({
        page: 1,
        limit: 10,
      })
    );

    return () => {
      funds.selectToken(null);
      handleClear();
    };
  }, []);

  useEffect(() => {
    if (
      funds.ethToUsd &&
      funds.tokenValueInEth &&
      fields.leverage &&
      fields.tokenAmount
    ) {
      let currentPrice = funds?.tokenValueInEth || 0;
      const collateralTokens =
        Number(fields.tokenAmount) * Number(fields.leverage);

      setFields((pre) => ({
        ...pre,
        collateral: Number(
          Number(currentPrice) * Number(collateralTokens)
        ).toFixed(18),
        collateralTokens: collateralTokens,
      }));
    }
  }, [
    funds.ethToUsd,
    funds.tokenValueInEth,
    fields.leverage,
    fields.tokenAmount,
  ]);

  useEffect(() => {
    getBalanceDetail(funds.web3Intraction, wallet);
  }, [funds.web3Intraction, wallet, funds.selectToken]);

  useEffect(() => {
    if (orderDetails && query) {
      if (query === "copy") {
        funds.selectToken(orderDetails.tokenId);
      }
      setFields((pre) => ({
        ...pre,
        ...orderDetails,
        tokenId: orderDetails.tokenId?._id,
        userId: orderDetails.userId?._id,
      }));
    }
  }, [JSON.stringify(orderDetails), query]);

  let getBalanceDetail = async (web3Intraction, wallet) => {
    if (!web3Intraction || !wallet?.account || !funds.selectToken) return;
    try {
      const amount = await web3Intraction.getBalance(wallet.account);
      setBalance(amount);
      await funds.fetchBalance(null, wallet.account);
    } catch (error) {
      setBalance(0);
    }
  };

  const handleChange = (e) => {
    if (loading || botLoading) return;

    const { name, value } = e.target;
    setFields((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const validateFeilds = () => {
    if (fields.tokenAmount <= 0) {
      return toastr.error("Qty should be greater then zero!");
    }

    if (!wallet.account) {
      return toastr.error("Please connect metamask first!");
    }

    if (Number(fields.tokenAmount) > Number(funds.tokenBalance)) {
      return toastr.error("You don't have enought token to open GTT position!");
    }

    if (Number(fields.collateral) > Number(balance)) {
      return toastr.error("you don't have enought balance for taking GTT!");
    }

    if (!fields.stopLoss || !fields.buyPrice || !fields.targetPrice) {
      return toastr.error("All Price Fields Required!");
    }

    if (Number(fields.stopLoss) > Number(funds.bitFinexValue)) {
      return toastr.error("Amount must be less then  token current amount!");
    }

    if (Number(fields.buyPrice) > Number(funds.bitFinexValue)) {
      return toastr.error(
        "Amount must be less then or equal to token current amount!"
      );
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = validateFeilds();
    if (error) return;

    try {
      const web3Intraction = new Web3Intraction("ethereum", settings);

      if (!funds.selectedToken && !web3Intraction)
        return toastr.error("Something went wrong!");

      // return

      setBotLoading(true);
      let data = {
        collateral: fields.collateral,
        tokenAddress:
          funds.selectedToken?.networkMode == "testnet"
            ? funds.selectedToken?.testnetContractAddress
            : funds.selectedToken?.contractAddress,
        decimals: funds.selectedToken?.decimals,
        tokenAmount: fields.tokenAmount,
        leverage: fields.leverage,
        position: fields.position === "long" ? 0 : 1,
        currentTicker: fields.buyPrice,
        reqId: Math.floor(100000 + Math.random() * 900000),
        userWallet: wallet.account,
      };

      const openPositionResp = await web3Intraction.openPosition(data);
      setBotLoading(false);

      // console.log(openPositionResp, "<===openPositionResp")
      if (!openPositionResp.status) {
        return toastr.error("Something went wrong");
      }
      const callback = async (response) => {
        if (response.status == "success") {
          await getBalanceDetail(funds.web3Intraction, wallet);

          toastr.success("GTT Position Successfully!");
          navigate("/positions");
          return;
        } else {
          toastr.error(response.message);
          return;
        }
      };
      data = {
        ...data,
        userId: user?._id,
        tokenId: funds.selectedToken._id,
        buyPrice: fields.buyPrice,
        targetPrice: fields.targetPrice,
        stopLoss: fields.stopLoss,
        type: "GTT",
        status: fields.buyPrice == funds.bitFinexValue ? "open" : "pending",
        transactionHash: openPositionResp.txHash || "",
        position: fields.position,
      };

      dispatch(makeBotRequest(data, callback));
    } catch (error) {
      console.log("err in submit func", error);
      setBotLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();

    let error = validateFeilds();
    if (error) return;

    try {
      const web3Intraction = new Web3Intraction("ethereum", settings);

      if (!funds.selectedToken && !web3Intraction)
        return toastr.error("Something went wrong!");

      setBotLoading(true);
      let data = {};
      let openPositionResp = null;
 
      if (Number(orderDetails.tokenAmount) > Number(fields.tokenAmount)) {

        ///for less amount
        data = {
          tokenAmount:
            Number(orderDetails.tokenAmount) - Number(fields.tokenAmount),
          collateral: Number(
            Number(orderDetails.collateral) - Number(fields.collateral)
          ).toFixed(18),
          ticker: 1,
        };
      } else if (
        Number(fields.tokenAmount) > Number(orderDetails.tokenAmount)
      ) {
        ///for greater amount

        data = {
          tokenAmount:
            Number(fields.tokenAmount) - Number(orderDetails.tokenAmount),
          collateral: Number(
            Number(fields.collateral) - Number(orderDetails.collateral)
          ).toFixed(18),
          ticker: 0,
        };
      } else {
        ///for equal amount

        data = {
          tokenAmount: Number(fields.tokenAmount),
          collateral: Number(fields.collateral),
 
        };
      }
      // else if

      data = {
        ...data,
        tokenAddress:
          funds.selectedToken?.networkMode == "testnet"
            ? funds.selectedToken?.testnetContractAddress
            : funds.selectedToken?.contractAddress,
        position: fields.position === "long" ? 0 : 1,
        currentTicker: fields.buyPrice,
        reqId: orderDetails.reqId,
        userWallet: wallet.account,
        decimals: funds.selectedToken?.decimals,
  

      };


      if (Number(fields.tokenAmount) !== Number(orderDetails.tokenAmount)) {
        openPositionResp = await web3Intraction.openPosition(data, "edit");

        if (!openPositionResp.status) {
          return toastr.error("Something went wrong");
        }
      }
      setBotLoading(false);

      // console.log(openPositionResp, "<===openPositionResp")

      const callback = async (response) => {
        if (response.status == "success") {
          await getBalanceDetail(funds.web3Intraction, wallet);

          toastr.success("GTT Position Successfully!");
          navigate("/positions");
          return;
        } else {
          toastr.error(response.message);
          return;
        }
      };
      data = {
        ...data,
        leverage: fields.leverage,
        userId: user?._id,
        tokenId: funds.selectedToken._id,
        buyPrice: fields.buyPrice,
        targetPrice: fields.targetPrice,
        stopLoss: fields.stopLoss,
        type: "GTT",
        status: fields.buyPrice == funds.bitFinexValue ? "open" : "pending",
        transactionHash: openPositionResp?.txHash || orderDetails.transactionHash || "",
        position: fields.position,
        collateral: Number(fields.collateral)?.toFixed(18),
        tokenAmount: Number(fields.tokenAmount),
        _id: fields._id,
      };
      dispatch(editBotRequest(data, callback));
    } catch (error) {
      console.log("err in submit func", error);
      setBotLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  const handleClear = (e, type) => {
    e && e.preventDefault();
    if (loading || botLoading) return;

    if (type === "cancel") {
      navigate("/bot");
    }

    setFields((pre) => ({
      ...pre,
      tokenAmount: 0,
      stopLoss: 0,
      targetPrice: 0,
      buyPrice: 0,
      leverage: 1,
      collateral: "",
      collateralTokens: 0,
      currentTicker: "",
      userId: "",
      tokenId: "",
      type: "GTT",
      status: "open",
      position: "long",
    }));
  };

  

  // console.log(fields.collateral,orderDetails?.collateral, "<==fields");
  return (
    <section className="botSection">
      <div className="bot_header d-flex align-items-center justify-content-between px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="bot_header_left d-flex align-items-center gap-3">
          <h4>{funds.selectedToken?.name || ""}</h4>
          {funds.selectedToken && (
            <>
              <p>{funds?.bitFinexValue || "--"}</p>
            </>
          )}
        </div>

        <div className="bot_header_right">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.713T12 11q-.425 0-.713.288T11 12v4q0 .425.288.713T12 17Zm0-8q.425 0 .713-.288T13 8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8q0 .425.288.713T12 9Zm0 13q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="trasaction_types d-flex align-items-start gap-5 px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="trasaction_type">
          <h6 className="tra_head">Transaction type</h6>

          <div className="bot_dropdown">
            <UncontrolledDropdown>
              <DropdownToggle>
                <div className="drop_wrap d-flex align-items-center">
                  <div className="chain_icon">
                    {funds.selectedToken?.icon && (
                      <img
                        src={funds.selectedToken?.icon}
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </div>
                  {funds.selectedToken?.name || "Select Token"}
                  <span className="ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ccc"
                        d="m8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
                      />
                    </svg>
                  </span>
                </div>
              </DropdownToggle>
              {query === "edit" && fields._id ? (
                ""
              ) : (
                <DropdownMenu>
                  {tradeTokens &&
                    tradeTokens.map((token) => (
                      <DropdownItem>
                        <div
                          className="d-flex"
                          onClick={() => funds.selectToken(token)}
                        >
                          <div className="chain_icon">
                            <img
                              src={token.icon}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {token.name}
                        </div>
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </div>

          {funds.selectedToken && (
            <FormGroup tag="fieldset">
              <div className="radio_common-btn d-flex mt-3 gap-3">
                {[
                  { value: "long", label: "Long" },
                  { value: "short", label: "Short" },
                ].map((data, index) => (
                  <FormGroup check key={index}>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        checked={fields.position === data.value}
                        onClick={(e) =>
                          handleChange({
                            target: {
                              name: "position",
                              value: data.value,
                            },
                          })
                        }
                      />{" "}
                      {data.label}
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </FormGroup>
          )}
        </div>
        <div className="trigger_type">
          <h6 className="tra_head">Trigger type</h6>

          <p className="text-white">
          The trigger price is the point at which your buy or sell order is made available to the exchange servers for processing.
          </p>
        </div>
      </div>

      {funds.selectedToken && (
        <>
          <BotForm
            fields={fields}
            handleChange={handleChange}
            balance={balance}
            handleSubmit={handleSubmit}
            handleEdit={handleEdit}
            botLoading={loading || botLoading}
            query={query}
            handleClear={handleClear}
          />
        </>
      )}
    </section>
  );
};

export default Bot;
