import useWallet from "Hooks/useWallet";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import Web3Intraction from "utils/web3Intraction";
import { addTransaction } from "store/actions";
import toastr from "toastr";

export default function Createpoolmod({ selectedToken, ...props }) {
  const dispatch = useDispatch();
  const { settings } = useSelector(({ Settings }) => Settings);
  const { user } = useSelector(({ Login }) => Login);
  const {
    AUDLoading } = useSelector(({ Transaction }) => Transaction)
  const { firstTokenAmount, secondTokenAmount } = props;
  const { account } = useWallet();

  const onClickAddLiquidity = async () => {
    try {
      const web3Instance = new Web3Intraction("ethereum", settings);
      const params = {
        firstToken: selectedToken.firstToken,
        secondToken: selectedToken.secondToken,
        amountA: firstTokenAmount,
        amountB: secondTokenAmount,
        toAddress: account
      }
      const netWorkUrl = web3Instance.getNetworkUrl();

      const data = await web3Instance.addLiquidity(params);
      const payload = {
        transactionHash: data?.transactionHash,
        rpc_url: netWorkUrl.url,
        explorerUrl : netWorkUrl?.blockExplorerUrls?.[0], 
        receiptStatus: data?.status ? "success" : "failed",
        blockchain: "ethereum",
        transType: 'add_liquidity',
        user: user?._id,
        tokenA: { ...selectedToken.firstToken, tokenAmount: firstTokenAmount },
        tokenB: { ...selectedToken.secondToken, tokenAmount: secondTokenAmount },
      }
      dispatch(addTransaction(payload), () => {
        props.onHide();
      });
    } catch (error) {
      toastr.error(error || "Something went wrong!");
    }

  }

  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="trasaxction-mod selectoken_mod"
    >
      <Button className="close-btn" onClick={props.onHide}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.75806 17.2431L12.0011 12.0001M17.2441 6.75708L12.0001 12.0001M12.0001 12.0001L6.75806 6.75708M12.0011 12.0001L17.2441 17.2431"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Button>
      <Modal.Body>
        <div className="pool_Crete_content">
          <h6 className="pool-head">You are creating a a pool</h6>

          <div className="pool-div">
            <h3>{selectedToken?.firstToken?.symbol}/{selectedToken?.secondToken?.symbol}</h3>
            <span>-</span>
            <div className="pool-coin">
              <img
                width="30"
                src={selectedToken?.firstToken?.icon}
                alt=""
                className="img-fluid" />
              /
              <img
                width="30"
                src={selectedToken?.secondToken?.icon}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="pool_div_list">
            <Button onClick={() => onClickAddLiquidity()} className="big-add-token Create-pool py-3">
              <p>Create Pool & Supply</p>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
