import React from "react";
import Fade from "react-reveal";
import { Button } from "react-bootstrap";
import useWallet from "Hooks/useWallet";
import { copyToClipboard } from "helpers/constants";

export default function Walletdashboard() {
  const { isActive, account } = useWallet();
  return (
    <Fade>
      <section className="wallet-content">
        <div className="d-flex align-items-center gap-lg-0 gap-3">
          <h5 class="heading-comp me-2">Active Ethereum address</h5>
          <div className="safe_gaurd">
            <span className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 14.9979C8 14.9979 13.9991 12.9982 13.9991 7.99902"
                  stroke="#1ABC7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9991 7.99891V1.99984C13.9991 1.99984 11.9994 1 8 1"
                  stroke="#1ABC7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00004 14.9979C8.00004 14.9979 2.00098 12.9982 2.00098 7.99902"
                  stroke="#1ABC7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.00098 7.99891V1.99984C2.00098 1.99984 4.00067 1 8.00004 1"
                  stroke="#1ABC7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9994 3.99951C8.00002 6.99904 7.00018 10.9984 7.00018 10.9984C7.00018 10.9984 6.00033 9.80501 5.00049 8.99873"
                  stroke="#1ABC7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p>Safe & secured</p>
          </div>
        </div>

        <p className="wallet_subpara">
          Deposit ETH and use ETHx in DeFi to earn staking rewards while
          exploring additional reward opportunities.
        </p>

        <ul className="wallet-list">
          <li>
            <div className="wallet-adrrees wallet_slect">
              <div className="wallet-name">
                <p>1</p>
              </div>
              <div className="wallet-adress d-flex justify-content-between align-items-center px-3 py-2">
                <div className="wallet_address_side">
                  <span>Address</span>

                  <div className="wallet_address d-flex align-items-center">
                    <h5>{account?.slice(0, 8)}...{account?.slice(38)}</h5>
                    <span className="ms-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_75_13011)">
                          <path
                            d="M4 7.5L7 10L11 5M7.5 14.5C6.58075 14.5 5.6705 14.3189 4.82122 13.9672C3.97194 13.6154 3.20026 13.0998 2.55025 12.4497C1.90024 11.7997 1.38463 11.0281 1.03284 10.1788C0.68106 9.32951 0.5 8.41925 0.5 7.5C0.5 6.58075 0.68106 5.6705 1.03284 4.82122C1.38463 3.97194 1.90024 3.20026 2.55025 2.55025C3.20026 1.90024 3.97194 1.38463 4.82122 1.03284C5.6705 0.68106 6.58075 0.5 7.5 0.5C9.35652 0.5 11.137 1.2375 12.4497 2.55025C13.7625 3.86301 14.5 5.64348 14.5 7.5C14.5 9.35652 13.7625 11.137 12.4497 12.4497C11.137 13.7625 9.35652 14.5 7.5 14.5Z"
                            stroke="#675AFF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_75_13011">
                            <rect width="15" height="15" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="wallet-check">
                <Button className="copy_btn-cta btnselct" onClick={() => copyToClipboard(account)}><p>Copy</p></Button>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </Fade>
  );
}
