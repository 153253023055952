import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function Graph({ symbol }) {
  const { candles } = useSelector((state) => state.Crypto);
  const rootRef = useRef(null);

  let makeChartData = () => {

    if (!candles || rootRef.current) return;

    // console.log(candles, "window load");

    let root = am5.Root.new("chartdiv");
    // Set themes
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create a stock chart
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Instantiating_the_chart
    let stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {})
    );

    // Set global number format
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
    root.numberFormatter.set("numberFormat", "#,###.00");

    // Create a main stock panel (chart)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Adding_panels
    let mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        wheelY: "zoomX",
        panX: true,
        panY: true,
      })
    );

    // Create value axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
          labels: {
            fill: am5.color("#fff"), // Set the fill color to red
            className: "custom-y-axis-labels"
          }
        }),
        extraMin: 0.0000000001, // adds some space for for main series
        tooltip: am5.Tooltip.new(root, {
          labels: {
            fill: am5.color("#fff"), // Set the fill color to red
            color:" #fff"
          }
        }),
        numberFormat: "#,###.0000000000",
        extraTooltipPrecision: 10,
        className: "custom-y-axis",
        color:" #fff",
        fill: am5.color("#fff"),
      })
    );

    let dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      
      })
    );

    // Add series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let valueSeries = mainPanel.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: symbol,
        clustered: false,
        valueXField: "Date",
        valueYField: "Close",
        highValueYField: "High",
        lowValueYField: "Low",
        openValueYField: "Open",
        calculateAggregates: true,
        xAxis: dateAxis,
        yAxis: valueAxis,
        legendValueText:
          "open: [bold]{openValueY.formatNumber('#,###.000000000')}[/] high: [bold]{highValueY.formatNumber('#,###.000000000')}[/] low: [bold]{lowValueY.formatNumber('#,###.000000000')}[/] close: [bold]{valueY.formatNumber('#,###.000000000')}[/]",
        legendRangeValueText: "",
      })
    );

    // Set main value series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Setting_main_series
    stockChart.set("stockSeries", valueSeries);

    // Add a stock legend
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/stock-legend/
    let valueLegend = mainPanel.plotContainer.children.push(
      am5stock.StockLegend.new(root, {
        stockChart: stockChart,
      })
    );

    // Create volume axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
    });

    volumeAxisRenderer.labels.template.set("forceHidden", true);
    volumeAxisRenderer.grid.template.set("forceHidden", true);

    let volumeValueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#.#a",
        height: am5.percent(20),
        y: am5.percent(100),
        centerY: am5.percent(100),
        renderer: volumeAxisRenderer,

      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let volumeSeries = mainPanel.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Volume",
        clustered: false,
        valueXField: "Date",
        valueYField: "Volume",
        xAxis: dateAxis,
        yAxis: volumeValueAxis,
        legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      })
    );

    volumeSeries.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.5,
    });

    // color columns by stock rules
    volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        return stockChart.getVolumeColor(dataItem);
      }
      return fill;
    });

    // Set main series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/#Setting_main_series
    stockChart.set("volumeSeries", volumeSeries);
    valueLegend.data.setAll([valueSeries, volumeSeries]);

    // Add cursor(s)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    mainPanel.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        yAxis: valueAxis,
        xAxis: dateAxis,
        snapToSeries: [valueSeries],
        snapToSeriesBy: "y!",
      })
    );

    // Add scrollbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = mainPanel.set(
      "scrollbarX",
      am5xy.XYChartScrollbar.new(root, {
        orientation: "horizontal",
        height: 90,
        visible: false,

      })
    );
    stockChart.toolsContainer.children.push(scrollbar);

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {

        }),
        tooltip: am5.Tooltip.new(root, {}),
        
      })
    );

    

    // dateAxis.setVisible(false);

    let sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          labels: {
            fill: am5.color("#FF0000") // Set the fill color to red
          }
        }),
      })
    );

    let sbSeries = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: "Close",
        valueXField: "Date",
        xAxis: sbDateAxis,
        yAxis: sbValueAxis,
      })
    );

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3,
    });

    // Function that dynamically loads data
    function loadData(ticker, series, granularity) {
      // Load external data
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Setting_data
      // am5.net
      //   .load(
      //     "https://www.amcharts.com/wp-content/uploads/assets/docs/stock/" +
      //       ticker +
      //       "_" +
      //       granularity +
      //       ".csv"
      //   )
      //   .then(function (result) {
      // Parse loaded data
      // let data = am5.CSVParser.parse(result.response, {
      //   delimiter: ",",
      //   skipEmpty: true,
      //   useColumnNames: true,
      // });

      let data = candles.map((data, index) => ({
        // time: data.time,
        Date: data.date_created,
        Open: parseFloat(data.open),
        High: parseFloat(data.high),
        Low: parseFloat(data.low),
        Close: parseFloat(data.close),
      }));

      // Process data (convert dates and values)
      let processor = am5.DataProcessor.new(root, {
        dateFields: ["Date"],
        dateFormat:
          granularity == "minute" ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd",
        numericFields: ["Open", "High", "Low", "Close", "Adj Close", "Volume"],
      });
      processor.processMany(data);
      // console.log(data, "<===data");
      // Set data
      am5.array.each(series, function (item) {
        item.data.setAll(data);
      });

      // });
    }

    // Load initial data for the first series
    let currentGranularity = "minute";
    loadData(symbol, [valueSeries, volumeSeries, sbSeries], currentGranularity);

    // Set up series type switcher
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/series-type-control/
    let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
      stockChart: stockChart,
    });

    seriesSwitcher.events.on("selected", function (ev) {
      setSeriesType(ev.item.id);
    });

    function getNewSettings(series) {
      let newSettings = [];
      am5.array.each(
        [
          "name",
          "valueYField",
          "highValueYField",
          "lowValueYField",
          "openValueYField",
          "calculateAggregates",
          "valueXField",
          "xAxis",
          "yAxis",
          "legendValueText",
          "stroke",
          "fill",
        ],
        function (setting) {
          newSettings[setting] = series.get(setting);
        }
      );
      return newSettings;
    }

    function setSeriesType(seriesType) {
      // Get current series and its settings
      let currentSeries = stockChart.get("stockSeries");
      let newSettings = getNewSettings(currentSeries);

      // Remove previous series
      let data = currentSeries.data.values;
      mainPanel.series.removeValue(currentSeries);

      // Create new series

      let series;
      switch (seriesType) {
        case "line":
          series = mainPanel.series.push(
            am5xy.LineSeries.new(root, newSettings)
          );
          break;
        case "candlestick":
        case "procandlestick":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, newSettings)
          );
          if (seriesType == "procandlestick") {
            series.columns.template.get("themeTags").push("pro");
          }
          break;
        case "ohlc":
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.OHLCSeries.new(root, newSettings)
          );
          break;
      }

      // Set new series as stockSeries
      if (series) {
        valueLegend.data.removeValue(currentSeries);
        series.data.setAll(data);
        stockChart.set("stockSeries", series);
        let cursor = mainPanel.get("cursor");
        if (cursor) {
          cursor.set("snapToSeries", [series]);
        }
        valueLegend.data.insertIndex(0, series);
      }
    }

    // Interval switcher
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/interval-control/
    let intervalSwitcher = am5stock.IntervalControl.new(root, {
      stockChart: stockChart,
      items: [
        {
          id: "1 minute",
          label: "1 minute",
          interval: { timeUnit: "minute", count: 1 },
        },
        {
          id: "1 day",
          label: "1 day",
          interval: { timeUnit: "minute", count: 1 },
        },
        {
          id: "1 week",
          label: "1 week",
          interval: { timeUnit: "week", count: 1 },
        },
        {
          id: "1 month",
          label: "1 month",
          interval: { timeUnit: "month", count: 1 },
        },
      ],
    });

    intervalSwitcher.events.on("selected", function (ev) {
      // Determine selected granularity
      currentGranularity = ev.item.interval.timeUnit;

      // Get series
      let valueSeries = stockChart.get("stockSeries");
      let volumeSeries = stockChart.get("volumeSeries");

      // Set up zoomout
      valueSeries.events.once("datavalidated", function () {
        mainPanel.zoomOut();
      });

      // Load data for all series (main series + comparisons)
      let promises = [];
      promises.push(
        loadData(
          symbol,
          [valueSeries, volumeSeries, sbSeries],
          currentGranularity
        )
      );
      am5.array.each(
        stockChart.getPrivate("comparedSeries", []),
        function (series) {
          promises.push(
            loadData(series.get("name"), [series], currentGranularity)
          );
        }
      );

      // Once data loading is done, set `baseInterval` on the DateAxis
      Promise.all(promises).then(function () {
        dateAxis.set("baseInterval", ev.item.interval);
        sbDateAxis.set("baseInterval", ev.item.interval);
      });
    });

    // Stock toolbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document?.getElementById("chartcontrols"),
      stockChart: stockChart,
      controls: [
        // am5stock.IndicatorControl.new(root, {
        //   stockChart: stockChart,
        //   legend: valueLegend,
        // }),
        // am5stock.DateRangeSelector.new(root, {
        //   stockChart: stockChart,
        // }),
        // am5stock.PeriodSelector.new(root, {
        //   stockChart: stockChart,
        // }),
        // intervalSwitcher,
        seriesSwitcher,
        // am5stock.DrawingControl.new(root, {
        //   stockChart: stockChart,
        // }),
        // am5stock.ResetControl.new(root, {
        //   stockChart: stockChart,
        // }),
        // am5stock.SettingsControl.new(root, {
        //   stockChart: stockChart,
        // }),
      ],
    });

    rootRef.current = root;
  };

  useEffect(() => {
    makeChartData();

    // return () => {
    //   if (rootRef.current) {
    //     rootRef.current.dispose();
    //     rootRef.current = null;
    //   }
    // };
  }, [candles]);

  return (
    <>
      <div
        id="chartcontrols"
        style={{ height: "auto", padding: "5px 45px 0 15px" }}
      ></div>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    </>
  );
}

export default Graph;
