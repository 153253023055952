import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

//store
import { getStakeTokens } from "store/actions";

///helpers
import useWallet from "Hooks/useWallet";
import Web3Intraction from "utils/web3Intraction";
import AssetsSlider from "./Components/assetsSlider";
import { WETH_TOKEN_ADDRESS, converCryptoToDollar } from "helpers/constants";

const Myassets = (props) => {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const { stakeTokens } = useSelector((state) => state.Stake);
  const { settings } = useSelector((state) => state.Settings);
  const [tokenList, settokenList] = useState([]);

  useEffect(() => {
    dispatch(getStakeTokens());
  }, []);

  useEffect(() => {
    let tokens = stakeTokens?.map((data) => ({
      ...data,
      balance: 0,
    }));
    settokenList(tokens);

    handleBalanceOfTokens(stakeTokens, settings);
  }, [JSON.stringify(stakeTokens), JSON.stringify(settings)]);

  const handleBalanceOfTokens = async (list, settings) => {
    try {
      if (list.length && wallet.isActive && settings) {
        const web3Intraction = new Web3Intraction("ethereum", settings);
        for (let i = 0; i < list.length; i++) {
          let value = await web3Intraction.getTokenPrice(
            list[i]?.contractAddress,
            wallet.account
          );
          let getEthValueInDollar = 0;
          if (value && value != "0") {
            let getValueInEth = await web3Intraction.getConverterPrice(
              { contractAddress: list[i]?.contractAddress },
              { contractAddress: WETH_TOKEN_ADDRESS },
              value
            );
            getEthValueInDollar = await converCryptoToDollar(
              getValueInEth,
              "ETH",
              5
            );
          }
          list[i].balance = Number(value).toFixed(2);
          list[i].balanceInUsd = getEthValueInDollar;
        }
        settokenList(list);
      }
    } catch (error) {
      console.log("err==> ", error);
    }
  };


  return (
    <section className="wallet_balnce_table mt-4">
      <Container>
        <div className="assets_wrapping">
          <div className="balance_left d-flex justify-content-between align-items-center w-100">
            <h5>Total Balance </h5>
            {/* <Button className="add_new_cta">+ Add New</Button> */}
          </div>

          <AssetsSlider tokenList={tokenList} />
        </div>
      </Container>
    </section>
  );
};

export default Myassets;
