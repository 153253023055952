import Web3 from "web3";
import { ContractFactory, Contract, ethers } from "ethers";

// Constant
import {
  getNetworkUrl,
  getContractDetails,
  factoryAddress,
  factoryAbi,
  pairTokensAbi,
} from "../helpers/constants";
import toastr from "toastr";

import routerAbi from "./routerAbi.json";
import tokenAbi from "./tokenAbi.json";

class Web3Intraction {
  constructor(blockchain, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);
    const provider = ethers.providers.getDefaultProvider(networkUrl?.url);

    if (provider && window.ethereum ) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        window.ethereum,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER.getSigner();
      this.routerContractSetting = getContractDetails(
        blockchain || "ethereum",
        settings,
        "routerContract"
      );

      this.factoryContractSetting = getContractDetails(
        blockchain || "ethereum",
        settings,
        "factoryContract"
      );

      this.stakeContractSetting = getContractDetails(
        blockchain || "ethereum",
        settings,
        "stakeContract"
      );

      this.leverageTradingSetting = getContractDetails(
        blockchain || "ethereum",
        settings,
        "leverageTradingContract"
      );

      if (networkUrl) {
        this.web3 = new Web3(networkUrl.url);
      }
    }
    this.settings = settings;
    this.networkUrl = networkUrl;
    // this.adminContractSetting = getContractDetails(
    //   blockchain || "ethereum",
    //   settings
    // );
  }

  getNetworkUrl() {
    return this.networkUrl;
  }
  getTransactionReceipt = (transactionHash, getToken) => {
    return new Promise(async (resolve, reject) => {
      this.contractInterval = setInterval(async () => {
        try {
          let receipt = await this.web3.eth.getTransactionReceipt(
            transactionHash
          );

          if (!!receipt) {
            if (getToken && !!receipt?.logs && !!receipt.logs[0]) {
              receipt.token_id = this.web3.utils.hexToNumberString(
                receipt.logs[0].topics[3]
              );
            }

            clearInterval(this.contractInterval);
            this.contractInterval = null;

            resolve(receipt);
            return;
          }
        } catch (error) {
          clearInterval(this.contractInterval);
          this.contractInterval = null;

          reject(error);
          return;
        }
      }, 15000);
    });
  };
  getNetworkUrl() {
    return this.networkUrl;
  }
  getTokenPairAddress = async (tokenAAddress, tokenBAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factoryContract = new ethers.Contract(
          factoryAddress,
          factoryAbi,
          this.PROVIDER
        );
        const pairAddress = await factoryContract.getPair(
          tokenAAddress,
          tokenBAddress
        );
        resolve(pairAddress);
      } catch (error) {
        reject(error);
      }
    });
  };

  getReservesOfPairTokens = async (pairContract) => {
    return new Promise(async (resolve, reject) => {
      try {
        const pairTokensContract = new ethers.Contract(
          pairContract,
          pairTokensAbi,
          this.PROVIDER
        );
        const pairReserves = await pairTokensContract.getReserves();
        resolve(pairReserves);
      } catch (error) {
        reject(error);
      }
    });
  };
  getNetworkUrl() {
    return this.networkUrl;
  }
  calculateMinAmounts(
    reserveA,
    reserveB,
    amountADesired,
    amountBDesired,
    slippage
  ) {
    const exchangeRate = reserveB / reserveA;
    const amountBMin = amountADesired * exchangeRate * (1 - slippage);
    const amountAMin = (amountBDesired / exchangeRate) * (1 - slippage);
    return { amountAMin, amountBMin };
  }

  async getTokensPriceAndPoolShare(tokenPairAddress) {
    return new Promise(async (resolve, reject) => {
      try {
        const pairContract = new ethers.Contract(
          tokenPairAddress,
          pairTokensAbi,
          this.PROVIDER
        );

        const [token0, token1] = await Promise.all([
          pairContract.token0(),
          pairContract.token1(),
        ]);

        const reserves = await pairContract.getReserves();
        const reserve0 = reserves[0];
        const reserve1 = reserves[1];

        const token0Contract = new ethers.Contract(
          token0,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );
        const token1Contract = new ethers.Contract(
          token1,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );

        const [decimals0, decimals1] = await Promise.all([
          token0Contract.decimals(),
          token1Contract.decimals(),
        ]);

        let price;
        if (Number(decimals1) > Number(decimals0)) {
          price =
            Number(Number(reserve1) / 10 ** Number(decimals1)) /
            Number(Number(reserve0) / 10 ** Number(decimals0));
        } else {
          price =
            Number(Number(reserve0) / 10 ** Number(decimals0)) /
            Number(Number(reserve1) / 10 ** Number(decimals1));
        }
        // const price = reserve1
        //   .mul(ethers.BigNumber.from(10).pow(decimals0))
        //   .div(reserve0);
        const poolShare0 = reserve0.div(
          ethers.BigNumber.from(10).pow(decimals0)
        );
        const poolShare1 = reserve1.div(
          ethers.BigNumber.from(10).pow(decimals1)
        );

        const data = {
          price: price,
          poolShare0: poolShare0.toString(),
          poolShare1: poolShare1.toString(),
        };
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
    // const tokenPairAbi = [...]; // Replace with actual ABI
  }

  addLiquidity = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          tokenA,
          tokenB,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          toAddress,
          deadline,
        } = params;
        // Address of the Uniswap V2 Router contract
        const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

        // Create a contract instance of the Uniswap V2 Router
        const routerContract = this.getContract(
          routerAbi,
          routerAddress,
          this.SIGNER
        );

        // Add liquidity 
        // Approve tokens for spending by the router
        const tokenAContract = new ethers.Contract(
          tokenA,
          tokenAbi,
          this.SIGNER
        );
        const tokenBContract = new ethers.Contract(
          tokenB,
          tokenAbi,
          this.SIGNER
        );

        let tokenAAllowence = await tokenAContract.allowance(
          toAddress,
          routerAddress
        );
        let tokenBAllowence = await tokenBContract.allowance(
          toAddress,
          routerAddress
        );
        tokenAAllowence = tokenAAllowence.toString();
        tokenBAllowence = tokenBAllowence.toString();

        if (amountADesired > tokenAAllowence) {
          const trans = await tokenAContract.approve(routerAddress, amountADesired);
          await trans.wait();
        }
        if (amountBDesired > tokenBAllowence) {
          const trans = await tokenBContract.approve(routerAddress, amountBDesired);
          await trans.wait();
        }

        const tx = await routerContract.addLiquidity(
          Web3.utils.toChecksumAddress(tokenA),
          Web3.utils.toChecksumAddress(tokenB),
          amountADesired.toString(),
          amountBDesired.toString(),
          Math.floor(amountAMin).toString(),
          amountBMin.toString(),
          Web3.utils.toChecksumAddress(toAddress),
          deadline,
          {
            gasLimit: 300000,
          }
        );
        // tx.wait();
        let receipt = await tx.wait();
        if (receipt?.status) {
          resolve({ txHash: tx.hash, ...receipt });
          return { status: "success" };
        } else {
          reject("Transaction failed!");
          return { status: "failed" };
        }
      } catch (e) {
        reject("Transaction failed!");
        return { status: "failed" };
      }
    });
  };
  getContractWeb3 = (abi, address) => {
    try {
      let contract = new this.web3.eth.Contract(abi, address);

      return contract;
    } catch (error) {
      return null;
    }
  };
  eth_sendTransaction = (params, getToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [params],
        });

        let receipt = await this.getTransactionReceipt(txHash, getToken);
        resolve({ txHash, ...receipt });
      } catch (error) {
        reject(error);
      }
    });
  };

  getTransactionReceipt = (transactionHash, getToken) => {
    return new Promise(async (resolve, reject) => {
      this.contractInterval = setInterval(async () => {
        try {
          let receipt = await this.web3.eth.getTransactionReceipt(
            transactionHash
          );

          if (!!receipt) {
            if (getToken && !!receipt?.logs && !!receipt.logs[0]) {
              receipt.token_id = this.web3.utils.hexToNumberString(
                receipt.logs[0].topics[3]
              );
            }

            clearInterval(this.contractInterval);
            this.contractInterval = null;

            resolve(receipt);
          }
        } catch (error) {
          clearInterval(this.contractInterval);
          this.contractInterval = null;

          reject(error);
        }
      }, 15000);
    });
  };
  addLiquidityWeb3 = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          tokenA,
          tokenB,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          toAddress,
          deadline,
        } = params;
        // Address of the Uniswap V2 Router contract
        const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

        // Create a contract instance of the Uniswap V2 Router
        const routerContract = this.getContractWeb3(
          this.routerContractSetting.abi,
          routerAddress
        );

        // Add liquidity
        // Approve tokens for spending by the router
        const tokenAContract = new ethers.Contract(
          tokenA,
          tokenAbi,
          this.SIGNER
        );
        const tokenBContract = new ethers.Contract(
          tokenB,
          tokenAbi,
          this.SIGNER
        );

        let tokenAAllowence = await tokenAContract.allowance(
          toAddress,
          routerAddress
        );
        let tokenBAllowence = await tokenBContract.allowance(
          toAddress,
          routerAddress
        );
        tokenAAllowence = tokenAAllowence.toString();
        tokenBAllowence = tokenBAllowence.toString();

        // if (amountADesired > tokenAAllowence) {
        // await tokenAContract.approve(routerAddress, amountADesired);
        // }
        // if (amountBDesired > tokenBAllowence) {
        // await tokenBContract.approve(routerAddress, amountBDesired);
        // }
        let transactionParameters = {
          to: routerAddress, // Required except during contract publications.
          from: toAddress, // must match user's active address.
          gasLimit: 300000,
          data: routerContract.methods
            .addLiquidity(
              tokenA,
              tokenB,
              amountADesired.toString(),
              amountBDesired.toString(),
              amountAMin.toString(),
              amountBMin.toString(),
              toAddress,
              deadline
            )
            .encodeABI(),
        };
        try {
          let receipt = await this.eth_sendTransaction(transactionParameters);
          resolve(receipt);

          callback && callback(null, receipt);
        } catch (error) {
          callback && callback(error.message);
          reject(error.message);
        }
      } catch (e) {
        reject("Transaction failed!");
        return { status: "failed" };
      }
    });
  };

  getTokenPrice = async (
    tokenAddress,
    userWallet,
    decimals = 0,
    callback = () => null
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.SIGNER
        );
        let tokenBalance = await tokenContract.balanceOf(userWallet);
        if (decimals && Number(decimals) != 0) {
          tokenBalance =
            Number(tokenBalance.toString()) / 10 ** Number(decimals);
        } else {
          tokenBalance = convertPriceToEther(tokenBalance);
        }
        resolve(tokenBalance);
      } catch (e) {
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };
  getBalance = async (userWallet, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        var balance = await this.PROVIDER.getBalance(userWallet); //Will give value in.
        balance = ethers.utils.formatEther(balance.toString());
        resolve(balance);
      } catch (e) {
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };

  convertPriceToEther = (price) => {
    return ethers.utils.parseEther(price?.toString())._hex;
    // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
  };

  getContract = (abi, address) => {
    try {
      let contract = new Contract(address, abi, this.SIGNER);
      return contract;
    } catch (error) {
      return null;
    }
  };

  getAccountBalance = async () => {
    try {
      const adminContract = this.getContract(
        JSON.stringify(this.adminContractSetting.abi),
        this.adminContractSetting.contractAddress
      );
      let PPT_balance = await adminContract.balanceOf(this.SIGNER.address);
      PPT_balance = ethers.utils.formatEther(PPT_balance);
      let balance = await this.PROVIDER.getBalance(this.SIGNER.address);
      return { balance: ethers.utils.formatEther(balance), PPT_balance };
    } catch (error) {
      return null;
    }
  };
  deployContract = (collection, callback = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factory = new ContractFactory(
          JSON.parse(collection?.data?.abi),
          collection.data.bytecode,
          this.SIGNER
        );

        const contract = await factory.deploy();
        let receipt = await contract.deployTransaction.wait();

        callback &&
          callback(null, { txHash: receipt.transactionHash, receipt });
        resolve({ txHash: receipt.transactionHash, receipt });
      } catch (error) {
        // callback && callback(error.message);
        reject(error.message);
      }
    });
  };

  verifyApproved = (userWallet, collection, callback, type = "") => {
    return new Promise(async (resolve, reject) => {
      if (
        (collection.abi || collection.data.contractAbi) &&
        collection.data.contractAddress
      ) {
        try {
          const contract = this.getContract(
            collection.abi ? collection.abi : collection.data.contractAbi,
            collection.data.contractAddress
          );

          if (!contract) {
            const error_message = "Invalid Contract";
            callback && callback(error_message);
            reject(error_message);
            return;
          }

          const isApproved = await contract.isApprovedForAll(
            userWallet,
            this.settings.walletAddress.publicKey
          );
          if (isApproved) {
            callback && callback(null, collection);
            resolve(collection);
            return;
          }

          try {
            const transaction = await contract.setApprovalForAll(
              this.settings.walletAddress.publicKey,
              true
            );

            const receipt = await transaction.wait();

            callback && callback(null, receipt);
            resolve(receipt);
          } catch (error) {
            callback && callback(error.message);
            reject(error.message);
          }
        } catch (error) {
          reject(error.message);
        }
      } else {
        const error_message = "No Collection Data!";
        callback && callback(error_message);
        reject(error_message);
      }
    });
  };

  getNonce = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        let nonce = await this.PROVIDER.getTransactionCount(address);
        resolve(nonce);
      } catch (error) {
        reject(error);
      }
    });
  };

  transaferToken = (contract, data, amount) => {
    return new Promise(async (resolve, reject) => {
      try {
        let balance = await contract.balanceOf(data?.from);
        if (Number(balance.toString()) <= Number(amount)) {
          reject({
            message: `You Don't have enough ${this.networkUrl.symbol}`,
          });
        }

        let numberOfTokens = convertToDecimal(amount, 18);

        let tokenTransferRes = await contract.transfer(
          data.to,
          numberOfTokens,
          {
            gasLimit: convertToHex("0x100000"), // 100000
            gasPrice: data.gasPrice,
          }
        );

        // let reciept = await tokenTransferRes.wait();

        resolve(tokenTransferRes);
      } catch (error) {
        error = JSON.parse(JSON.stringify(error));
        reject(error);
      }
    });
  };

  sendTransaction = (item, buyType, userWallet = "") => {
    return new Promise(async (resolve, reject) => {
      const adminContract = this.getContract(
        JSON.stringify(item?.collection_id.contractAbi),
        item?.collection_id.contractAddress
      );
      const erc20 = this.getContract(
        JSON.stringify(this.adminContractSetting.abi),
        this.adminContractSetting.contractAddress
      );
      let transaction = "";

      //get Gas price START
      // let gas_price = await this.PROVIDER.getGasPrice();
      // gas_price = convertToHex(parseInt(gas_price));
      if (buyType == 1) {
        item = { ...item, price: 0.05 / item.price };
      }

      try {
        const options = {
          value: ethers.utils
            .parseUnits(item.price?.toString() || "0", "ether")
            .toHexString(),
        };

        buyType == 1 &&
          (await erc20.increaseAllowance(
            item?.collection_id.contractAddress,
            JSON.stringify(item.price * 10 ** 18)
          ));

        let balance = 0;

        if (buyType == 1) {
          balance = await erc20.balanceOf(this.SIGNER.address);
          balance = ethers.utils.formatEther(balance);
          if (Number(balance.toString()) <= Number(item.price)) {
            return toastr.error(`You Don't have enough PPT`);
          }

          if (item?.collection_id?.collectionType == "ships") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              item?.collection_id?.collectionType,
              buyType == 0
                ? ethers.utils.parseUnits(
                    item.price?.toString() || "0",
                    "ether"
                  )
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              item.attributes.Durablity,
              // item.attributes._storm,
              // item.attributes.Fire,
              // item.attributes.Ice,
              {
                // gasLimit: convertToHex("0x100000"), // 100000
                // gasPrice: gas_price,
              }
            );
          } else if (item?.collection_id?.collectionType == "pirates") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              buyType == 0
                ? ethers.utils.parseUnits(
                    item.price?.toString() || "0",
                    "ether"
                  )
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              item.attributes.level,
              item.attributes.charge,
              {
                // gasLimit: convertToHex("0x100000"), // 100000
                // gasPrice: gas_price,
              }
            );
          } else {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              buyType == 0
                ? ethers.utils.parseUnits(
                    item.price?.toString() || "0",
                    "ether"
                  )
                : JSON.stringify(item.price * 10 ** 18),
              buyType,
              {
                // gasLimit: convertToHex("0x100000"), // 100000
                // gasPrice: gas_price,
              }
            );
          }
        } else {
          if (item?.collection_id?.collectionType == "ships") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              item?.collection_id?.collectionType,
              item?.subcategory_id?.catName || "Galleon",
              "UserMint",
              ethers.utils.parseUnits(item.price.toString() || "0", "ether"),
              item.attributes.level,
              item.attributes.Durablity,
              // item.attributes.storm,
              // item.attributes.Fire,
              // item.attributes.Ice,
              {
                // gasLimit: convertToHex("0x300000"), // 100000
                // gasPrice: gas_price,
                ...options,
              }
            );
          } else if (item?.collection_id?.collectionType == "pirates") {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              ethers.utils.parseUnits(item.price?.toString() || "0", "ether"),
              "UserMint",
              item.attributes.level,
              item.attributes.charge,
              {
                // gasLimit: convertToHex("0x30000"), // 100000
                // gasPrice: gas_price,
                ...options,
              }
            );
          } else {
            transaction = await adminContract.redeem(
              userWallet != "" ? userWallet : this.SIGNER.address,
              item.voucher,
              ethers.utils.parseUnits(item.price?.toString() || "0", "ether"),
              "UserMint",
              {
                // gasLimit: convertToHex("0x300000"), // 100000
                // gasPrice: gas_price,
                ...options,
              }
            );
          }
        }
        const receipt = await transaction.wait();
        resolve({ ...receipt, buyAmount: Number(balance.toString()) });
      } catch (error) {
        reject(error);
      }
    });
  };
  getAmountsOut = (tokenA, tokenB) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Address of the Uniswap V2 Router contract
        const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

        // Create a contract instance of the Uniswap V2 Router
        const router = new ethers.Contract(
          routerAddress,
          [
            "function getAmountsOut(uint256 amountIn, address[] memory path) public view returns (uint256[] memory)",
          ],
          this.SIGNER
        );
        let price;
        this.PROVIDER.on("block", async (blockNumber) => {
          // Emitted on every block change
          let value = await router.getAmountsOut(1000000000000000000, [
            tokenB,
            tokenA,
          ]);
          price = value[1].toString();
        });
        resolve({ price: price });
      } catch (error) {
        reject(error);
      }
    });
  };
  stake = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { tokenAmount, tokenAddress, decimals, reqId, userWallet } =
          params;

        // Create a contract instance of the Stake contract
        const stakeContract = this.getContract(
          this.stakeContractSetting?.abi,
          this.stakeContractSetting?.contractAddress,
          this.SIGNER
        );

        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.SIGNER
        );

        let tokenAllowence = await tokenContract.allowance(
          userWallet,
          this.stakeContractSetting?.contractAddress
        );
        tokenAllowence = tokenAllowence.toString();
        const tokenAmountWithDecimal =
          Number(tokenAmount) * 10 ** Number(decimals);
        if (Number(tokenAmountWithDecimal) > tokenAllowence) {
          const txn = await tokenContract.increaseAllowance(
            this.stakeContractSetting?.contractAddress,
            tokenAmountWithDecimal
          );
          await txn.wait();
        }
        const tx = await stakeContract.Stake(
          Web3.utils.toChecksumAddress(tokenAddress),
          tokenAmountWithDecimal,
          reqId
        );
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        reject(e);
      }
    });
  };
  unstake = async (reqId, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Create a contract instance of the Stake contract
        const stakeContract = this.getContract(
          this.stakeContractSetting?.abi,
          this.stakeContractSetting?.contractAddress,
          this.SIGNER
        );

        const tx = await stakeContract.Unstake(Number(reqId));
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        reject(e);
      }
    });
  };
  openPosition = async (params, type, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          collateral,
          tokenAddress,
          decimals,
          tokenAmount,
          leverage,
          position,
          currentTicker,
          reqId,
          userWallet,
        } = params;

        // Create a contract instance of the Leverage Trading contract
        const leverageTradingContract = this.getContract(
          this.leverageTradingSetting?.abi,
          this.leverageTradingSetting?.contractAddress,
          this.SIGNER
        );

        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.SIGNER
        );

        let tokenAllowence = await tokenContract.allowance(
          userWallet,
          this.leverageTradingSetting?.contractAddress
        );
        tokenAllowence = tokenAllowence.toString();

        const newTokenAmount =
          Number(decimals) != 18
            ? Number(tokenAmount) * 10 ** decimals
            : convertPriceToEther(tokenAmount);

        if (Number(newTokenAmount) > tokenAllowence) {
          const txn = await tokenContract.increaseAllowance(
            this.leverageTradingSetting?.contractAddress,
            newTokenAmount
          );
          await txn.wait();
        }
        const options = { value: Web3.utils.toWei(collateral.toString()) };
        let tx = null;
        if (type == "edit") {
          tx = await leverageTradingContract.updatePostion(
            Web3.utils.toWei(collateral.toString()),
            Web3.utils.toChecksumAddress(tokenAddress),
            newTokenAmount,
            reqId,
            params.ticker,
            { ...options, gasLimit: "300000" }
          );
        } else {
          tx = await leverageTradingContract.depositAndOpenPostion(
            Web3.utils.toWei(collateral.toString()),
            Web3.utils.toChecksumAddress(tokenAddress),
            newTokenAmount,
            leverage,
            position,
            Web3.utils.toWei(currentTicker.toString()),
            reqId,
            options
          );
        }
        console.log(options, "<====options");
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        console.log(e, "<===err in openPosition");
        reject(e);
      }
    });
  };
  closePosition = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { amountToTransfer, reqId, decimals } = params;
        // Create a contract instance of the Leverage Trading contract
        const leverageTradingContract = this.getContract(
          this.leverageTradingSetting?.abi,
          this.leverageTradingSetting?.contractAddress,
          this.SIGNER
        );

        // const transferTokens = parseInt(
        //   Number(amountToTransfer) * 10 ** Number(decimals)
        // );

        const transferTokens =
          Number(decimals) != 18
            ? Number(amountToTransfer) * 10 ** decimals
            : convertPriceToEther(amountToTransfer);
        const tx = await leverageTradingContract.ClosePositions(
          reqId,
          transferTokens
        );
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        console.log(e, "error");
        reject(e);
      }
    });
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return ethers.utils.parseEther(price?.toString())._hex;

  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};
export const convertToHex = (value) => ethers.utils.hexlify(parseInt(value));

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToDecimal = (value, decimal) => {
  return ethers.utils.parseUnits(value, decimal);
};
export const formatEther = (value) => {
  return ethers.utils.formatEther(value);
};

export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) =>
  Web3.utils.fromWei(number, unit || "ether");
