import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Form, FormGroup, Label, Input, UncontrolledTooltip } from "reactstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import toastr from "toastr";

//store
import { getStakeTokens, addTransaction } from "store/actions";

//helper
import Web3Intraction from "utils/web3Intraction";
import { WETH_TOKEN_ADDRESS, getNetworkUrl } from "helpers/constants";
import { useDebounce } from "Hooks/useDebounce";
import useWallet from "Hooks/useWallet";

///components
import { ClickButton } from "Component/InputElements";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "ICO Token",
    "Sold Globaly",
    "Reserve Fund",
    "Team Advisor",
    "ICO Program",
    "Pre Token",
  ],
  datasets: [
    {
      label: "# of Votes",
      data: [20, 20, 20, 20, 20, 20],
      backgroundColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderColor: [
        "rgba(29, 31, 35, 1)",
        "rgba(29, 31, 35, 1)",
        "rgba(29, 31, 35, 1)",
        "rgba(29, 31, 35, 1)",
        "rgba(29, 31, 35, 1)",
      ],
      borderWidth: 3,
    },
  ],
};
export const options = {
  plugins: {
    legend: {
      labels: {
        color: "#fff",
      },
    },
  },
};

const Buyplatformtable = (props) => {
  const dispatch = useDispatch();
  const wallet = useWallet();

  const { stakeTokens } = useSelector(({ Stake }) => Stake);
  const { settings } = useSelector(({ Settings }) => Settings);
  const { user } = useSelector((state) => state.Login);

  const [state, setState] = useState({
    platFormToken: null,
    web3Instance: null,
    tokenAmount: 0,
    cryptoAmount: 0,
  });

  const [chartData, setChartData] = useState(data);

  const [filter, setFilter] = useState({
    limit: 20,
    page: 1,
  });
  const [loading, setloading] = useState(false);

  //get all Stake Tokens
  useEffect(() => {
    dispatch(getStakeTokens(filter));
  }, []);

  //get all Stake Tokens
  useEffect(() => {
    setState((pre) => ({
      ...pre,
      platFormToken: stakeTokens?.find((data) => data.platformToken),
    }));
  }, [stakeTokens]);

  useEffect(() => {
    if (settings) {
      const web3Instance = new Web3Intraction("ethereum", settings);
      let chartdata = JSON.parse(JSON.stringify(chartData));

      const { tokenPercentage } = settings


      chartdata.datasets[0].data = [tokenPercentage?.iconToken || 0, tokenPercentage?.soldGlobaly || 0, tokenPercentage?.reserveFund || 0, tokenPercentage?.teamAdvisor || 0, tokenPercentage?.icoProgram || 0, tokenPercentage?.preToken || 0]

      setChartData(chartdata)
      setState((pre) => ({
        ...pre,
        web3Instance,
      }));
    }
  }, [JSON.stringify(settings)]);

  const handleAmount = useDebounce(async (value) => {
    if (!state.platFormToken.contractAddress)
      return toastr.error("Platform Token not found!");
    try {
      if (!value || value == "0") {
        return setState((pre) => ({
          ...pre,
          cryptoAmount: 0,
        }));
      }
      setloading(true);
      try {
        const networkUrl = getNetworkUrl("ethereum", settings);
        await wallet.switchNetwork(networkUrl?.chainId || "5");
      } catch (error) {
        return toastr.error("User reject request!");
      }
      let price = await state.web3Instance.getConverterPrice(
        { contractAddress: state.platFormToken.contractAddress },
        { contractAddress: WETH_TOKEN_ADDRESS },
        value,
        state.platFormToken.tokenDecimal
      );
      setloading(false);

      setState((pre) => ({
        ...pre,
        cryptoAmount: price,
      }));
    } catch (error) {
      setloading(false);
      toastr.error(error.message);
    }
  }, 500);

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (name === "tokenAmount") {
      handleAmount(value || 0);
    }
    setState((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const handleBuyToken = async (e) => {
    if (!settings || !state.web3Instance)
      return toastr.error("Something went wrong!");
    if (!state.tokenAmount || state.tokenAmount == "0")
      return toastr.error("Token amount required!");

    try {
      setloading(true);

      if (!wallet.isActive) {
        await wallet.connect(settings);
      }
      let receipt = await state.web3Instance.buyTokenUsingSwap(
        { contractAddress: WETH_TOKEN_ADDRESS },
        { contractAddress: state.platFormToken.contractAddress },
        state.tokenAmount,
        state.cryptoAmount
      );

      const payload = {
        transactionHash: receipt?.hash,
        rpc_url: receipt?.networkUrl.url,
        explorerUrl: receipt?.networkUrl?.blockExplorerUrls?.[0],
        receiptStatus: receipt?.status ? "success" : "failed",
        blockchain: "ethereum",
        transType: "buy",
        user: user?._id,
        tokenA: { ...state.platFormToken, tokenAmount: state.tokenAmount },
        cryptoAmount: (state.cryptoAmount).toFixed(18),
      };
      dispatch(
        addTransaction(payload, () => {
          setloading(false);
          setState((pre) => ({
            ...pre,
            tokenAmount: 0,
            cryptoAmount: 0,
          }));
        })
      );
    } catch (error) {
      setloading(false);
      toastr.error(error);
    }
  };

  return (
    <>
      <div className="shared_head text-start mb-3 mt-3">
        <h3 className="card_heading">{props?.heading}</h3>
        <p className="buy_show">
          Shows latest Tokens in Leverage with useful information
        </p>
      </div>

      <section className="buy_platform_tokennew mt-3">
        <Row>
          <Col lg={6} md={12} sm={12}>
            <div className="backgronud_wrap">
              <div className="inner_buyer">
                <div className="inner_top_heade">
                  <h6>Purchase Tokens</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Ethereum address to receive token</h5>
                    <span id="buytooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM11.5 6C11.2348 6 10.9804 6.10536 10.7929 6.29289C10.6054 6.48043 10.5 6.73478 10.5 7C10.5 7.26522 10.6054 7.51957 10.7929 7.70711C10.9804 7.89464 11.2348 8 11.5 8H12C12.2652 8 12.5196 7.89464 12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6H11.5ZM10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12H11V15H10C9.73478 15 9.48043 15.1054 9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071C9.48043 16.8946 9.73478 17 10 17H14C14.2652 17 14.5196 16.8946 14.7071 16.7071C14.8946 16.5196 15 16.2652 15 16C15 15.7348 14.8946 15.4804 14.7071 15.2929C14.5196 15.1054 14.2652 15 14 15H13V11C13 10.7348 12.8946 10.4804 12.7071 10.2929C12.5196 10.1054 12.2652 10 12 10H10Z"
                          fill="#A6AAB0"
                        />
                      </svg>
                    </span>

                    <UncontrolledTooltip placement="top" target="buytooltip">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Inventore, harum.
                    </UncontrolledTooltip>
                  </div>
                </div>

                <div className="amount_Section mt-5">
                  <Form>
                    <div className="d-flex alig-items-center gap-3">
                      <FormGroup>
                        <Label for="exampleEmail">
                          Amount of tokens to buy
                        </Label>

                        <div className="inputToken_buy">
                          <div className="input_number">
                            <Input
                              type="number"
                              name="tokenAmount"
                              id="exampleEmail"
                              placeholder="25000"
                              value={state.tokenAmount}
                              onChange={handleChange}
                            />
                          </div>

                          <p>{state.platFormToken?.name}</p>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleEmail">Payment option</Label>

                        <div className="inputToken_buy">
                          <div className="input_number d-flex align-items-center">
                            {/* <Button class="btn btn-secondary">{"ETH"}</Button> */}
                            <p className="chainnae m-0">ETH</p>

                            <p className="amount_para text-truncate w-75" id="amountid">{(state.cryptoAmount).toFixed(Number(state?.platFormToken?.tokenDecimal))}</p>

                            <UncontrolledTooltip placement="top" target="amountid">
                              {(state.cryptoAmount).toFixed(Number(state?.platFormToken?.tokenDecimal))}
                            </UncontrolledTooltip>
                          </div>

                        </div>
                      </FormGroup>
                    </div>

                    <div className="radio_select mt-4">
                      <h6>Or Select an amount</h6>
                      <ul>
                        {[250, 1000, 2000, 4000].map((data, index) => (
                          <li key={index}>
                            <div className="wrapping_input">
                              <input
                                type="radio"
                                id={"r-value" + index}
                                value={data}
                                onClick={(e) =>
                                  handleChange({
                                    target: {
                                      name: "tokenAmount",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              <label
                                class={` radio ${state.tokenAmount == data && "active-radio"
                                  }`}
                                for={"r-value" + index}
                              >
                                {data}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="button_purchanse mt-5 mb-5 text-center">
                      <ClickButton
                        className="btn-purchanse"
                        onClick={handleBuyToken}
                        disabled={loading}
                        btnText={"PURCHASE"}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className="backgronud_wrap">
              <div className="inner_status">
                <div className="charrt_here">
                  <h5 className="text-center text-white py-3">Status</h5>
                  <Doughnut data={chartData} options={options} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Buyplatformtable;
