import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Fade from "react-reveal";
import Walletbalance from "./walletbalance";
import Myassets from "./myassets";
import Leaderboard from "./leaderboard";
import { useDispatch } from "react-redux";
import { getLeaderboard } from "store/actions";


const Dashboardpage = () => {
  const dispatch = useDispatch();
  const [filters, setFiters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1
  });
  useEffect(() => {
    dispatch(getLeaderboard(filters));
  }, [JSON.stringify(filters)])
  return (
    <Fade>
      <section className="dashboard_first my-3">
        <Container fluid className="p-0">

          <Row>
            <Col lg={8} md={12} sm={12}>
              <Walletbalance />
              <Myassets />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <Leaderboard />
            </Col>
          </Row>

        </Container>
      </section>
    </Fade>
  );
};

export default Dashboardpage;
