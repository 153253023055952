import * as TYPES from "./actionTypes"

export const openOrder = (user, callback) => {
  return {
    type: TYPES.OPEN_ORDER,
    payload: { user, callback },
  }
}

export const openOrderSuccessful = user => {
  return {
    type: TYPES.OPEN_ORDER_SUCCESSFUL,
    payload: user,
  }
}

export const openOrderFailed = user => {
  return {
    type: TYPES.OPEN_ORDER_FAILED,
    payload: user,
  }
}

export const updateOrder = (user, callback) => {
  return {
    type: TYPES.UPDATE_ORDER,
    payload: { user, callback },
  }
}

export const updateOrderSuccessful = user => {
  return {
    type: TYPES.UPDATE_ORDER_SUCCESSFUL,
    payload: user,
  }
}

export const updateOrderFailed = user => {
  return {
    type: TYPES.UPDATE_ORDER_FAILED,
    payload: user,
  }
}

/* Get orders List */
export const getOrders = data => ({
  type: TYPES.GET_ORDERS,
  payload: data,
})

export const getOrdersSuccess = orders => ({
  type: TYPES.GET_ORDERS_SUCCESS,
  payload: orders,
})

export const getOrdersFail = error => ({
  type: TYPES.GET_ORDERS_FAIL,
  payload: error,
})
/* Get orders List END */

/* Get Leaderboard */
export const getLeaderboard = data => ({
  type: TYPES.GET_LEADERBOARD,
  payload: data,
})

export const getLeaderboardSuccess = leaderboard => ({
  type: TYPES.GET_LEADERBOARD_SUCCESS,
  payload: leaderboard,
})

export const getLeaderboardFail = error => ({
  type: TYPES.GET_LEADERBOARD_FAIL,
  payload: error,
})
/* Get Leaderboard END */