import React, { useState, useEffect } from "react";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import Hotpairs from "../Hotpairs";
import CustomPagination from "Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getTradeTokenRequest,
  getOrders,
  getTokenCurrentPrice,
  updateOrder,
} from "store/actions";
import moment from "moment-timezone";
import toastr from "toastr";
import Web3Intraction from "utils/web3Intraction1";
import useWallet from "Hooks/useWallet";
import { calculateTransferToken, getContractDetails, getNetworkUrl } from "helpers/constants";
var W3CWebSocket = require("websocket").w3cwebsocket;

const Position = () => {
  const wallet = useWallet();
  const { totalOrders, orders } = useSelector(({ Order }) => Order);
  const { user, loading } = useSelector(({ Login }) => Login);
  const { settings } = useSelector(({ Settings }) => Settings);
  const { tradeTokens } = useSelector(({ Crypto }) => Crypto);

  const dispatch = useDispatch();
  const [newOrders, setNewOrders] = useState(orders);
  const [closeOrderLoading, setCloseOrderLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1,
    user: user?._id,
  });

  //set orders into new state
  useEffect(() => {
    setNewOrders(orders);
  }, [orders]);

  //get order and token data
  useEffect(() => {
    if (user) {
      dispatch(getOrders({ ...filters }));
      dispatch(getTradeTokenRequest());
    }
  }, [user, filters]);

  //pagination
  const onPageChange = (page) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  const totalPages = Math.ceil(totalOrders / filters?.limit);

  //get token balance
  async function getTokenBalance(tradeTokenDetails, settings, decimals, type) {
    if (settings && tradeTokenDetails) {
      const leverageContract = getContractDetails(
        "ethereum",
        settings,
        type
      )
      try {
        const networkUrl = getNetworkUrl("ethereum", settings);
        await wallet.switchNetwork(networkUrl?.chainId || "5");
      } catch (error) {
        return;
      }
      const web3Intraction = new Web3Intraction("ethereum", settings);
      if (tradeTokenDetails && wallet.account) {
        const tokenBalance = await web3Intraction.getTokenPrice(
          tradeTokenDetails?.tokenAddress,
          leverageContract?.contractAddress,
          decimals
        );
        return tokenBalance;
      }
    }
  };

  //get token details using contract address
  function getTokenDetailsByContractAddress(tokensList, contractAddress) {
    try {
      for (const token of tokensList) {
        if (
          token &&
          token.contractAddress &&
          (token.contractAddress === contractAddress ||
            token.testnetContractAddress === contractAddress)
        ) {
          return token;
        }
      }
      return null; // Return null if the contractAddress is not found in the list
    } catch (e) {
      return null;
    }
  }

  // Get live profit and loss using socket
  useEffect(() => {
    async function manage() {
      if (orders.length > 0) {
        let _orders = [...orders];
        for (let i = 0; i < orders.length; i++) {
          if (_orders[i].status == "open") {
            let currentPrice;
            const tokenDetails = await getTokenDetailsByContractAddress(
              tradeTokens,
              orders[i]?.tokenAddress
            );

            //Socket for live P&L
            const client = new W3CWebSocket("wss://api-pub.bitfinex.com/ws/2");
            client.onmessage = async (msg) => {
              const resp = JSON.parse(msg.data.toString());
              if (resp[1] && resp[1].length == 10) {
                currentPrice = resp[1][6];
                const totalPNL = await calculateTransferToken(
                  _orders[i],
                  currentPrice
                );
                console.log(totalPNL, "totalPNL");
                _orders[i].PNL = totalPNL;
                _orders[i].latestPrice = currentPrice;
                setNewOrders(_orders);
              }
            };

            let msg = JSON.stringify({
              event: "subscribe",
              channel: "ticker",
              symbol: `t${tokenDetails &&
                tokenDetails.bitFinexSymbol &&
                tokenDetails?.bitFinexSymbol
                }`,
            });

            client.onopen = () => client.send(msg, "msg");
          }
          //End Socket for live P&L
        }
      }
    }
    manage();
  }, [JSON.stringify(orders), JSON.stringify(tradeTokens)]);

  //cancel order
  const handleCancelOrder = (item) => async (e) => {
    e.preventDefault();
    setCloseOrderLoading(true);
    try {
      const web3Intraction = new Web3Intraction("ethereum", settings);
      if (wallet.account) {
        const tokenDetails = await getTokenDetailsByContractAddress(
          tradeTokens,
          item.tokenAddress
        );
        const tokenBalance = await getTokenBalance(item, settings, tokenDetails.decimals, "leverageTradingContract");
        if (Number(tokenBalance) < Number(item.tokenAmount)) {
          const stakeTokenBalance = await getTokenBalance(item, settings, tokenDetails.decimals, "stakeContract");
          if ((Number(stakeTokenBalance) + Number(tokenBalance)) < Number(item.tokenAmount)) {
            return toastr.error("Not enought token, try again after some time.");
          }
        }

        let params = {
          amountToTransfer: Number(item.tokenAmount),
          reqId: Number(item.reqId),
          tokenAddress: item.tokenAddress,
          decimals: tokenDetails.decimals,
          userWallet: wallet.account,
        };

        try {
          const closePositionResp = await web3Intraction.closePosition(params);
          if (closePositionResp.status) {
            const callback = async (response) => {
              if (response.status == "success") {
                setCloseOrderLoading(false);
                dispatch(getOrders({ ...filters }));
                toastr.success("Close Position Successfully!");
                return;
              } else {
                setCloseOrderLoading(false);
                toastr.error(response.message);
                return;
              }
            };
            dispatch(
              updateOrder(
                { _id: item?._id, status: "cancel", PNL: 0, latestPrice: 0 },
                callback
              )
            );
          } else {
            setCloseOrderLoading(false);
            return toastr.error("Something went wrong");
          }
        } catch (error) {
          setCloseOrderLoading(false);
          return toastr.error("Something went wrong!");
        }
      } else {
        setCloseOrderLoading(false);
        return toastr.error("Something went wrong!");
      }
    } catch (error) {
      setCloseOrderLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  //close order
  const handleSubmit = (item) => async (e) => {
    e.preventDefault();
    setCloseOrderLoading(true);
    try {
      const tokenDetails = await getTokenDetailsByContractAddress(
        tradeTokens,
        item?.tokenAddress
      );
      const web3Intraction = new Web3Intraction("ethereum", settings);
      if (tokenDetails && wallet.account) {
        const callback = async (response) => {
          if (response.data.length == 10) {
            const PNL = await calculateTransferToken(item, response.data[6]);

            const tokenBalance = await getTokenBalance(item, settings, tokenDetails.decimals, "leverageTradingContract");
            if (Number(tokenBalance) < (Number(item.tokenAmount) + Number(PNL))) {
              const stakeTokenBalance = await getTokenBalance(item, settings, tokenDetails.decimals, "stakeContract");
              if ((Number(stakeTokenBalance) + Number(tokenBalance)) < (Number(item.tokenAmount) + Number(PNL))) {
                setCloseOrderLoading(false);

                return toastr.error("Not enought token, try again after some time.");

              }
            }

            let params = {
              amountToTransfer: Number(item.tokenAmount) + Number(PNL),
              reqId: Number(item.reqId),
              decimals: tokenDetails.decimals
            };

            try {
              const closePositionResp = await web3Intraction.closePosition(
                params
              );
              if (closePositionResp.status) {
                const callback = async (response) => {
                  if (response.status == "success") {
                    setCloseOrderLoading(false);
                    dispatch(getOrders({ ...filters }));
                    toastr.success("Close Position Successfully!");
                    return;
                  } else {
                    setCloseOrderLoading(false);
                    toastr.error(response.message);
                    return;
                  }
                };
                dispatch(
                  updateOrder(
                    { _id: item?._id, status: "close", PNL: item?.PNL || 0, latestPrice: item?.latestPrice || 0 },
                    callback
                  )
                );
              } else {
                setCloseOrderLoading(false);
                return toastr.error("Something went wrong");
              }
            } catch (error) {
              setCloseOrderLoading(false);
              return toastr.error("Something went wrong!");
            }
          }
        };
        dispatch(getTokenCurrentPrice(tokenDetails.bitFinexSymbol, callback));
      } else {
        setCloseOrderLoading(false);
        return toastr.error("Something went wrong!");
      }
    } catch (error) {
      setCloseOrderLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  const handleInvestment = (item) => async (e) => {
    e.preventDefault();
    if (loading) return;
    try {
      const callback = async (response) => {
        if (response.status == "success") {
          dispatch(getOrders({ ...filters }));
          toastr.success(
            ` Successfully ${item.isInvestment === "yes" ? "removed" : "added"
            } in investment`
          );
          return;
        } else {
          setCloseOrderLoading(false);
          toastr.error(response.message);
          return;
        }
      };
      dispatch(
        updateOrder(
          {
            _id: item?._id,
            isInvestment: item.isInvestment === "yes" ? "no" : "yes",
          },
          callback
        )
      );
    } catch (error) {
      return toastr.error("Something went wrong!");
    }
  };

  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Hotpairs tradeTokens={tradeTokens} />
        {/* <Searchpair searchKeyword={searchKeyword} /> */}
        <div className="shared_head text-start mb-3 mt-3">
          <h3 className="card_heading">Position</h3>
          {/* <p  className="buy_show">Shows latest Tokens in Leverage with useful information</p> */}
        </div>
        <section className="Opendertable-table pairprice_chart mt-3">
          <div className="pairshared_table">
            <div className="d-flex justify-content-center">
              {closeOrderLoading && <Spinner style={{ color: "#fff" }} />}
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Invested Amount</th>
                  <th>Leverage</th>
                  <th>Total Amount</th>
                  <th>Open Price</th>
                  <th>Position</th>
                  <th>Latest Price</th>
                  <th>P&L</th>
                  <th>Status</th>
                  <th>Created Date</th>
                  {/* <th>Price USD</th> */}
                  <th>Action</th>
                  {user?.role === "EXPERT" && <th>Investment</th>}
                </tr>
              </thead>
              <tbody>
                {(newOrders || []).map((item) => {
                  return (
                    <tr
                      key={item?._id}
                      className={`${item.status == "open" && "open-position"}`}
                    >
                      <td>
                        {
                          getTokenDetailsByContractAddress(
                            tradeTokens,
                            item?.tokenAddress
                          )?.name
                        }
                      </td>

                      <td>{item?.tokenAmount}</td>
                      <td>{item?.leverage}</td>
                      <td>
                        {Number(item?.tokenAmount) * Number(item?.leverage) +
                          Number(item?.tokenAmount)}
                      </td>
                      <td>{item?.currentTicker}</td>
                      <td>{item?.position}</td>
                      <td className={`${item?.latestPrice < item?.currentTicker ? "danger" : "success"}`}>
                        {(item?.latestPrice && parseFloat(Number(item?.latestPrice).toFixed(12))) || 0}
                      </td>
                      <td className={`${item?.PNL < 0 ? "danger" : "success"}`}>
                        {(item?.PNL && Number(item?.PNL).toFixed(3)) || 0}
                      </td>
                      <td>
                        <span className="success-position">{item?.status}</span>
                      </td>
                      <td>
                        {item?.date_created
                          ? moment(item?.date_created).format("YYYY-MM-DD")
                          : ""}
                      </td>

                      <td>
                        {item?.status == "open" ? (
                          <Button
                            className="connect_wallet_cta"
                            onClick={handleSubmit(item, "close")}
                            disabled={closeOrderLoading}
                          >
                            Close Order
                          </Button>
                        ) : item?.status == "close" ? (
                          "Position Closed"
                        ) : item?.status == "pending" ? (
                          <Button
                            className="connect_wallet_cta"
                            onClick={handleCancelOrder(item)}
                            disabled={closeOrderLoading}
                          >
                            Cancel Orders
                          </Button>
                        ) : item?.status == "cancel" ? (
                          "Position Canceled"
                        ) : (
                          ""
                        )}
                      </td>

                      {user?.role === "EXPERT" && item?.status == "close" && (
                        <td>
                          <div className="toggle_here d-flex">
                            <label className="switch me-3">
                              <input
                                type="checkbox"
                                checked={item.isInvestment === "yes"}
                                onClick={handleInvestment(item)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <CustomPagination
                currentPage={filters?.page}
                totalPages={totalPages}
                onPageChange={(page) => onPageChange(page)}
                maxPagesToShow={5}
              />
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default Position;
