import React, { useEffect } from "react";
import Searchpair from "../Searchpair";
import { Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTopTradePairsRequest } from "store/actions";

const Tradepair = (props) => {
  const { loadingTradePairs, topTradePairs } = useSelector(({ Crypto }) => Crypto);
  const dispatch = useDispatch();

   useEffect(()=>{
     dispatch(getTopTradePairsRequest({page : 1 , limit : 10}));
  },[dispatch]);

  return (
    <>
      <Searchpair />
      <section className="trade_pair common-bg mt-3 pb-0">
        <Container fluid>
          <div className="tradepair_head d-flex align-items-center">
            <span className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="21"
                viewBox="0 0 15 21"
                fill="none"
              >
                <path
                  d="M6 1.66797L6.625 0.835938C6.78125 0.625 7.03125 0.5 7.29297 0.5C7.75 0.5 8.125 0.875 8.125 1.33203V2.19922C8.125 2.71094 8.33594 3.20312 8.70703 3.55469L12.0156 6.71094C13.9219 8.53125 15 11.0547 15 13.6914C15 17.4531 11.9531 20.5 8.19141 20.5H7.5C3.35938 20.5 0 17.1406 0 13V12.8516C0 10.9453 0.757813 9.11719 2.10547 7.76953L2.24219 7.63281C2.40625 7.46875 2.63281 7.375 2.86719 7.375C3.35547 7.375 3.75 7.76953 3.75 8.25781V11.75C3.75 13.1289 4.87109 14.25 6.25 14.25C7.62891 14.25 8.75 13.1289 8.75 11.75V11.5977C8.75 10.8945 8.46875 10.2188 7.97266 9.72266L6.46484 8.21484C5.52734 7.27734 5 6 5 4.67188C5 3.58984 5.35156 2.53125 6 1.66797Z"
                  fill="white"
                />
              </svg>
            </span>
            <h4 className="m-0 text-white">{props.heading}</h4>
          </div>
           {loadingTradePairs && 
            <div className="d-flex justify-content-center">
               <Spinner style={{color : "#fff"}} />
            </div>
           }
          <div className="trade_pair_table d-lg-flex justify-content-between gap-4 mt-4">
            <ul>
              {(topTradePairs || []).slice(0, 5).map((item, idx) => {
                let isactive = item.isactive || true;
                return (
                  <li key={idx}>
                    <div className="table_main_tr d-flex align-items-center justify-content-between">
                      <Link to="">
                        <div className="table_chain_name d-flex align-items-center">
                          <div className="table_chain_img me-2">
                            <img
                              src={item?.primaryToken?.icon}
                              alt=""
                              className="img-fluid"
                            />
                         
                          </div>
                          <div className="table_chain_img me-2">
                            <img
                              src={item?.secondryToken?.icon}
                              alt=""
                              className="img-fluid"
                            />
                         
                          </div>
                        
                          <div className="table_chain_name text-start">
                            <p>
                              {item?.primaryToken?.symbol}
                              {""}/{""}
                              <span>{item?.secondryToken?.symbol}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="table_price_dollor">
                        <p className="col-green">{item.chainprice}</p>
                      </div>
                      <div className="table_th_ranking">
                        {isactive ? (
                          <>
                            <p className="col-green d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                              >
                                <path
                                  d="M3.99622 2.83385L3.08709 1.92471C2.75236 1.58998 2.20964 1.58998 1.87491 1.92471C1.54017 2.25945 1.54017 2.80216 1.87491 3.1369L2.78405 4.04602L0.390137 6.43994H6.39014V0.439941L3.99622 2.83385Z"
                                  fill="#15a847"
                                />
                              </svg>
                              0%
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="col-red d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                              >
                                <path
                                  d="M3.99622 2.83385L3.08709 1.92471C2.75236 1.58998 2.20964 1.58998 1.87491 1.92471C1.54017 2.25945 1.54017 2.80216 1.87491 3.1369L2.78405 4.04602L0.390137 6.43994H6.39014V0.439941L3.99622 2.83385Z"
                                  fill="#EA3943"
                                />
                              </svg>
                              0%
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <ul>
              {(topTradePairs || []).slice(5, 11).map((item, idx) => {
                let isactive = item.isactive || true;
                return (
                  <li key={idx}>
                    <div className="table_main_tr d-flex align-items-center justify-content-between">
                      <Link to="">
                        <div className="table_chain_name d-flex align-items-center">
                          <div className="table_chain_img me-2">
                            <img
                              src={item?.primaryToken?.icon}
                              alt=""
                              className="img-fluid"
                            />
                         
                          </div>
                          <div className="table_chain_img me-2">
                            <img
                              src={item?.secondryToken?.icon}
                              alt=""
                              className="img-fluid"
                            />
                         
                          </div>
                        
                          <div className="table_chain_name text-start">
                            <p>
                              {item?.primaryToken?.symbol}
                              {""}/{""}
                              <span>{item?.secondryToken?.symbol}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="table_price_dollor">
                        <p className="col-green">{item.chainprice}</p>
                      </div>
                      <div className="table_th_ranking">
                        {isactive ? (
                          <>
                            <p className="col-green d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                              >
                                <path
                                  d="M3.99622 2.83385L3.08709 1.92471C2.75236 1.58998 2.20964 1.58998 1.87491 1.92471C1.54017 2.25945 1.54017 2.80216 1.87491 3.1369L2.78405 4.04602L0.390137 6.43994H6.39014V0.439941L3.99622 2.83385Z"
                                  fill="#15a847"
                                />
                              </svg>
                              0%
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="col-red d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                              >
                                <path
                                  d="M3.99622 2.83385L3.08709 1.92471C2.75236 1.58998 2.20964 1.58998 1.87491 1.92471C1.54017 2.25945 1.54017 2.80216 1.87491 3.1369L2.78405 4.04602L0.390137 6.43994H6.39014V0.439941L3.99622 2.83385Z"
                                  fill="#EA3943"
                                />
                              </svg>
                              0%
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Tradepair;
