import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";

const Invest = (props) => {
  const { settingData } = props;
  return (
    <Fade>
      <section className="Invest_sec position-relative comon_pad">
        <div className="invest-grad"></div>
        <Container>
          <div className="invest_dec_heading text-start">
            <Row>
              <Col lg={5} md={6} sm={12}>
                <h2 className="Section_head">
                  {(settingData?.second && settingData?.second?.title) ? settingData?.second?.title : "InVest in $IT. Leverage $IT. Make $IT"}
                </h2>
              </Col>

              <Col lg={5} md={6} sm={12} className="offset-lg-2">
                <p>
                  {(settingData?.second && settingData?.second?.title) ? settingData?.second?.title : "With a steadfast commitment to maintaining the highest level of security, IT creates a safe and secure trading environment where users can trade with confidence."}
                </p>
              </Col>
            </Row>
          </div>

          <div className="invest_boxes_icon mt-lg-5">
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Fade delay={100}>
                  <div className="box_main">
                    <div className="iconhere">
                      {settingData?.cardSection && settingData.cardSection[0]?.icon ? <img src={settingData.cardSection[0]?.icon}></img> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M19.8667 12.5333L20.85 14.8333C20.9167 15.0333 21.1 15.15 21.2834 15.1666L23.7834 15.4C24.3167 15.45 24.5167 16.1 24.1334 16.45L22.25 18.1C22.1 18.2333 22.0334 18.4333 22.0834 18.6166L22.6334 21.0666C22.75 21.5833 22.2 21.9833 21.7334 21.7166L19.5834 20.4333C19.4167 20.3333 19.2 20.3333 19.0334 20.4333L16.8834 21.7166C16.4334 21.9833 15.8667 21.5833 15.9834 21.0666L16.5334 18.6166C16.5834 18.4333 16.5167 18.2333 16.3667 18.1L14.4834 16.45C14.0834 16.1 14.3 15.45 14.8334 15.4L17.3334 15.1666C17.5334 15.15 17.7 15.0333 17.7834 14.85L18.7667 12.55C18.9667 12.05 19.65 12.05 19.8667 12.5333Z"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M30.6456 17.4077C30.6887 11.1485 25.6495 6.03943 19.3903 5.99635C13.1311 5.95327 8.02204 10.9925 7.97897 17.2517C7.93589 23.5109 12.9751 28.6199 19.2343 28.663C25.4935 28.7061 30.6026 23.6669 30.6456 17.4077Z"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17.7667 28.55L14.95 35.6333L11.3834 31.55L6.00003 32.05L9.5667 23.1166"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8334 28.55L23.6667 35.6333L27.2167 31.55L32.6167 32.05L29.0334 23.1166"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                    </div>
                    <h4>{settingData?.cardSection && settingData.cardSection[0]?.title ? settingData.cardSection[0]?.title : "Social Trading"}</h4>
                    <p>
                      {settingData?.cardSection && settingData.cardSection[0]?.description ? settingData.cardSection[0]?.description : "The Social trading: Replicate successful traders, learn from a collaborative community, and improve investment performance in a transparent......."}
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Fade delay={300}>
                  <div className="box_main">
                    <div className="iconhere">
                      {settingData?.cardSection && settingData.cardSection[1]?.icon ? <img src={settingData.cardSection[1]?.icon}></img> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M22.9833 14.4833C22.9833 14.4833 21.2833 12.9833 18.3 14.0333C15.55 14.9833 15.6833 18.7499 19.85 19.7499"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.45 24.9498C16.45 24.9498 18.15 26.4498 21.1333 25.3998C23.9 24.4332 23.7667 20.6665 19.5833 19.6665"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.0167 13.6666V11.2166"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.0167 28.2165V25.7665"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.7167 35.1C28.2126 35.1 35.1 28.2126 35.1 19.7166C35.1 11.2207 28.2126 4.33331 19.7167 4.33331C11.2207 4.33331 4.33333 11.2207 4.33333 19.7166C4.33333 28.2126 11.2207 35.1 19.7167 35.1Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                    </div>
                    <h4>{settingData?.cardSection && settingData.cardSection[1]?.title ? settingData.cardSection[1]?.title : "Built-in Analytics"}</h4>
                    <p>
                      {settingData?.cardSection && settingData.cardSection[1]?.description ? settingData.cardSection[1]?.description : "Built-in analytics provides integrated data analysis tools, offering insights and performance metrics to optimize decision-making and enhance ...."}
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Fade delay={500}>
                  <div className="box_main">
                    <div className="iconhere">
                      {settingData?.cardSection && settingData.cardSection[2]?.icon ? <img src={settingData.cardSection[2]?.icon}></img> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M28.6142 15.8976C30.3406 13.6719 29.9357 10.4682 27.71 8.74184C25.4843 7.01551 22.2805 7.42035 20.5542 9.64607C18.8279 11.8718 19.2327 15.0756 21.4584 16.8019C23.6841 18.5282 26.8879 18.1234 28.6142 15.8976Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.7331 31.4332C14.7331 25.9999 19.1498 21.5832 24.5831 21.5832C30.0164 21.5832 34.4331 25.9999 34.4331 31.4332"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.3565 15.9982C15.287 14.262 14.6339 12.1001 12.8977 11.1696C11.1615 10.2391 8.99971 10.8922 8.06917 12.6284C7.13863 14.3646 7.79174 16.5264 9.52794 17.4569C11.2641 18.3875 13.4259 17.7344 14.3565 15.9982Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.3335 27.35C4.3335 23.55 7.41683 20.4667 11.2168 20.4667C14.0335 20.4667 16.4502 22.15 17.5168 24.5833"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                    </div>
                    <h4>{settingData?.cardSection && settingData.cardSection[2]?.title ? settingData.cardSection[2]?.title : "Earn by copy trading"}</h4>
                    <p>
                      {settingData?.cardSection && settingData.cardSection[2]?.description ? settingData.cardSection[2]?.description : "Earn by copy trading: Profit by mirroring successful traders, leveraging expertise for potential investment gains. Profit by mirroring successful traders'....."}
                    </p>
                  </div>
                </Fade>
              </Col>

              <Col lg={4} md={6} sm={12} className="offset-lg-2">
                <Fade delay={800}>
                  <div className="box_main">
                    <div className="iconhere">
                      {settingData?.cardSection && settingData.cardSection[3]?.icon ? <img src={settingData.cardSection[3]?.icon}></img> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M19.8667 12.5333L20.85 14.8333C20.9167 15.0333 21.1 15.15 21.2834 15.1666L23.7834 15.4C24.3167 15.45 24.5167 16.1 24.1334 16.45L22.25 18.1C22.1 18.2333 22.0334 18.4333 22.0834 18.6166L22.6334 21.0666C22.75 21.5833 22.2 21.9833 21.7334 21.7166L19.5834 20.4333C19.4167 20.3333 19.2 20.3333 19.0334 20.4333L16.8834 21.7166C16.4334 21.9833 15.8667 21.5833 15.9834 21.0666L16.5334 18.6166C16.5834 18.4333 16.5167 18.2333 16.3667 18.1L14.4834 16.45C14.0834 16.1 14.3 15.45 14.8334 15.4L17.3334 15.1666C17.5334 15.15 17.7 15.0333 17.7834 14.85L18.7667 12.55C18.9667 12.05 19.65 12.05 19.8667 12.5333Z"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M30.6456 17.4077C30.6887 11.1485 25.6495 6.03943 19.3903 5.99635C13.1311 5.95327 8.02203 10.9925 7.97896 17.2517C7.93588 23.5109 12.9751 28.6199 19.2343 28.663C25.4935 28.7061 30.6025 23.6669 30.6456 17.4077Z"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17.7667 28.55L14.95 35.6333L11.3834 31.55L6.00002 32.05L9.56669 23.1166"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8334 28.55L23.6667 35.6333L27.2167 31.55L32.6167 32.05L29.0334 23.1166"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                    </div>
                    <h4>{settingData?.cardSection && settingData.cardSection[3]?.title ? settingData.cardSection[3]?.title : "Stock trading API."}</h4>
                    <p>
                      {settingData?.cardSection && settingData.cardSection[3]?.description ? settingData.cardSection[3]?.description : "Stock trading API: Real-time market data and trade execution for seamless stock trading integration and custom application development."}
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Fade delay={1000}>
                  <div className="box_main">
                    <div className="iconhere">
                      {settingData?.cardSection && settingData.cardSection[4]?.icon ? <img src={settingData.cardSection[4]?.icon}></img> :
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M22.9833 14.4833C22.9833 14.4833 21.2833 12.9833 18.3 14.0333C15.55 14.9833 15.6833 18.7499 19.85 19.7499"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M16.45 24.9498C16.45 24.9498 18.15 26.4498 21.1333 25.3998C23.9 24.4332 23.7666 20.6665 19.5833 19.6665"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.0167 13.6666V11.2166"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.0167 28.2165V25.7665"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19.7167 35.1C28.2127 35.1 35.1 28.2126 35.1 19.7166C35.1 11.2207 28.2127 4.33331 19.7167 4.33331C11.2207 4.33331 4.33334 11.2207 4.33334 19.7166C4.33334 28.2126 11.2207 35.1 19.7167 35.1Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                    </div>
                    <h4>{settingData?.cardSection && settingData.cardSection[4]?.title ? settingData.cardSection[4]?.title : "Smart order routing"}</h4>
                    <p>
                      {settingData?.cardSection && settingData.cardSection[4]?.description ? settingData.cardSection[4]?.description : "Smart order routing: Automated system intelligently routes orders across multiple liquidity sources to optimize execution quality and minimize trading costs."}
                    </p>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Invest;
