import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Stake Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* openOrder({ payload: { user, callback } }) {
  try {
    const response = yield call(API.openOrder, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.openOrderFailed(response.message));
    }
    yield put(ACTIONS.openOrderSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.openOrderFailed("Internal Server Error"));
  }
}

function* updateOrder({ payload: { user, callback } }) {
  try {
    const response = yield call(API.updateOrder, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.updateOrderFailed(response.message));
    }
    yield put(ACTIONS.updateOrderSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.updateOrderFailed("Internal Server Error"));
  }
}

function* getOrders({ payload }) {
  try {
    const response = yield call(API.getOrders, payload)
    if (response.status == "failure") {
      return yield put(ACTIONS.getOrdersFail(response.message))
    }

    yield put(ACTIONS.getOrdersSuccess(response))
  } catch (error) {
    yield put(ACTIONS.getOrdersFail("Internal Server Error"))
  }
}

function* getLeaderboard({ payload }) {
  try {
    const response = yield call(API.getLeaderboard, payload)
    if (response.status == "failure") {
      return yield put(ACTIONS.getLeaderboardFail(response.message))
    }

    yield put(ACTIONS.getLeaderboardSuccess(response))
  } catch (error) {
    yield put(ACTIONS.getLeaderboardFail("Internal Server Error"))
  }
}

export function* Order() {
  yield takeEvery(TYPES.OPEN_ORDER, openOrder);
  yield takeEvery(TYPES.UPDATE_ORDER, updateOrder);
  yield takeEvery(TYPES.GET_ORDERS, getOrders);
  yield takeEvery(TYPES.GET_LEADERBOARD, getLeaderboard);
}

function* OrderSaga() {
  yield all([fork(Order)]);
}

export default OrderSaga;
