import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authTokenHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const postLoginWithOtp = (data) => post(url.POST_LOGIN_OTP, data);
export const getCurrentUser = (data) => post(url.POST_LOGIN, data);

export const validateWallet = (data)=> post(url.VALIDATE_WALLET, data)

//Verify OTP
export const postVerifyOtp = (data) => post(url.POST_VERIFY_OTP, data);

//Send OTP
export const postsendOtp = (data) => post(url.POST_SEND_OTP, data);

// Register
export const postRegister = (data) => post(url.POST_REGISTER, data);

// Profile
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);

export const postForgetPwd = (data) => post(url.FORGET_PASSWORD, data);
export const postResetPwd = (data) => put(url.CHANGE_PASSWORD, data);

//all pairs
export const getAllPairsListApi = (data) => post(url.GET_ALL_PAIRS_LIST, data);

export const getTradeTokensApi = (data) => post(url.GET_TRADE_TOKENS, data);
export const getTradeDetails = (id) => get(url.GET_TRADE_DETAILS + "/" + id);
export const getTokenCurrentPrice = (symbol) => get(url.GET_TOKEN_CURRENT_PRICE + "/" + symbol);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authTokenHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};

export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);
// Settings
export const getSettingsApi = (data) => get(url.GET_SETTINGS, data);

//transactions
export const getTransactionsApi = (data) => post(url.GET_TRANSACTIONS, data);
export const addTransactionApi = (data) => post(url.ADD_TRANSACTION, data);

export const addInvestmentApi = (data) => post(url.ADD_INVESTMENT, data);

export const getTopTradePairsApi = (data) =>
  post(url.GET_TOP_TRADE_PAIRS, data);
export const pairDetails = (data) => post(url.GET_PAIR_DETAILS + "/" + data);

// Stake APIs
export const stakeAmount = (data) => post(url.ADD_STAKE_AMOUNT, data);
export const unstakeAmount = (data) => post(url.UNSTAKE_AMOUNT, data);
export const getStakeTokens = (data) => get(url.GET_STAKE_TOKENS, data);
export const getStakeList = (data) => post(url.GET_STAKE_LIST, data);

//Orders
export const openOrder = (data) => post(url.OPEN_ORDER, data);
export const updateOrder = (data) => post(url.UPDATE_ORDER, data);
export const getOrders = (data) => post(url.GET_ORDERS, data);
export const getLeaderboard = (data) => post(url.GET_LEADERBOARD, data);

//experts
export const getExpertsApi = (data) => post(url.GET_EXPERTS, data);

// Blogs
export const getBlogs = (data) => post(url.GET_BLOGS, data);
export const postBlog = (data) => post(url.ADD_BLOG, data);
export const getBlog = (id) => get(url.GET_BLOG + "/" + id);
export const putBlog = (data) => post(url.UPDATE_BLOG, data);
export const deleteBlog = (data) => post(url.DELETE_BLOG, data);

///Get candle list

export const getCandlesList = (data) => post(url.GET_CANDLES_LIST, data);

//Bots
export const makeBotRequest = (data) => post(url.MAKE_BOT_REQUEST, data);
export const editBotRequest = (data) => post(url.EDIT_BOT_REQUEST, data);
export const cancelBotRequest = (data) => post(url.CANCEL_BOT_REQUEST, data);
export const getBotRequests = (data) => post(url.GET_BOT_REQUESTS, data);
