import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import { Fade } from "react-reveal";
import { Table } from "reactstrap";
import Selecttokenmod from "./Selecttokenmod";
import { useDispatch, useSelector } from "react-redux";
import { addTransaction, getAllPairsListRequest, getStakeList, getStakeTokens, unstakeAmount } from "store/actions";
import useWallet from "Hooks/useWallet";
import Web3Intraction from "utils/web3Intraction1";
import toastr from "toastr";
import { addStakeAmount } from "store/actions"
import moment from "moment-timezone"
import { Spinner } from "react-bootstrap";

export default function Staking() {
  const dispatch = useDispatch();
  const wallet = useWallet()

  //redux selectors
  const { stakeTokens, stakeList, totoalStakeList } = useSelector(({ Stake }) => Stake);
  const { settings } = useSelector(state => state.Settings);
  const { user } = useSelector(({ Login }) => Login);

  //all states
  const [state, setState] = useState({ tab: "tab1" });
  const [selecttoken, setselectoken] = useState(false);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  // const [Createpool, setCreatepool] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenAmountCal, settokenAMountForCal] = useState(0);
  const [lockTime, setLockTime] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [selectedTokenData, setSelectedTokenData] = useState({
    firstToken: null
  });
  const [addStakeLoading, setAddStakeLoading] = useState(false);
  const [filter, setFilter] = useState({
    limit: 20,
    page: 1
  })
  //get all token
  useEffect(() => {
    dispatch(getStakeTokens());
  }, []);

  //get all Stake Tokens
  useEffect(() => {
    dispatch(getStakeList(filter));
  }, []);

  // convert LockTimePeriod into hr,min,sec
  useEffect(() => {
    const seconds = settings?.stakeContract?.LockTimePeriod;
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var hDisplay = h > 0 ? h + (d * 24) : "00";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";
    setLockTime(`${hDisplay}h,${mDisplay}m,${sDisplay}s`)
  }, [settings])

  //get token balance
  useEffect(() => {
    (async () => {
      if (settings && selectedTokenData.firstToken != null) {
        const web3Intraction = new Web3Intraction("ethereum", settings);
        if (selectedTokenData && selectedTokenData.firstToken && wallet.account) {
          const tokenBalance = await web3Intraction.getTokenPrice(selectedTokenData.firstToken.contractAddress, wallet.account, selectedTokenData.firstToken.tokenDecimal)
          setTokenBalance(tokenBalance);
        }
      }
    })();
  }, [selectedTokenData, settings, wallet])

  //Stake amount
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddStakeLoading(true);
    try {
      if (Number(tokenBalance) < Number(tokenAmount)) {
        setAddStakeLoading(false);
        return toastr.error("You don't have enought balance");
      }
      const web3Intraction = new Web3Intraction("ethereum", settings);
      if (selectedTokenData && selectedTokenData.firstToken && tokenAmount && wallet.account) {
        const params = {
          tokenAmount: tokenAmount,
          tokenAddress: selectedTokenData?.firstToken?.contractAddress,
          decimals: selectedTokenData?.firstToken?.tokenDecimal,
          reqId: Math.floor(100000 + Math.random() * 900000),
          userWallet: wallet.account
        }
        const stakeResp = await web3Intraction.stake(params);
        if (stakeResp.status) {
          const callback = async (response) => {
            if (response.status == "success") {
              const netWorkUrl = web3Intraction.getNetworkUrl();
              const payload = {
                transactionHash: stakeResp?.transactionHash,
                rpc_url: netWorkUrl.url,
                explorerUrl: netWorkUrl?.blockExplorerUrls?.[0],
                receiptStatus: stakeResp?.status ? "success" : "failed",
                blockchain: "ethereum",
                transType: 'stake',
                user: user?._id,
                tokenA: { ...selectedTokenData.firstToken, tokenAmount: tokenAmount },
              }
              await dispatch(addTransaction(payload));
              await dispatch(getStakeList(filter));
              setAddStakeLoading(false);
              toastr.success("Stake Amount Successfully!");
              setState({ tab: "tab2" })
              return;
            } else {
              setAddStakeLoading(false);
              toastr.error(response.message);
              return;
            }
          }
          let _params = {
            ...params,
            transactionHash: stakeResp.transactionHash,
            tokenSymbol: selectedTokenData?.firstToken?.symbol,
            endTime: moment().unix() + Number(settings?.stakeContract?.LockTimePeriod)
          }
          dispatch(addStakeAmount(_params, callback));
        } else {
          setAddStakeLoading(false);
          return toastr.error("Something went wrong");
        }
      } else {
        setAddStakeLoading(false);
        return toastr.error("Something went wrong!");
      }
    } catch (error) {
      setAddStakeLoading(false);
      return toastr.error("Something went wrong!");
    }
  }
  //unstake amount
  const handleUnstake = (stake) => async (e) => {
    e.preventDefault();
    setUnstakeLoading(true);
    try {
      const web3Intraction = new Web3Intraction("ethereum", settings);
      if (stake?.reqId && wallet.account) {
        const unstakeResp = await web3Intraction.unstake(stake?.reqId);
        if (unstakeResp.status) {
          setUnstakeLoading(false);
          const callback = async (response) => {
            if (response.status == "success") {
              const netWorkUrl = web3Intraction.getNetworkUrl();
              const tokenAmount = (Number(stake?.tokenAmount) + ((Number(stake?.tokenAmount) * Number(settings?.stakeContract?.roi)) / 100)) - (((Number(stake?.tokenAmount) + ((Number(stake?.tokenAmount) * Number(settings?.stakeContract?.roi)) / 100)) * Number(settings?.stakeContract?.StakeFee)) / 100);
              const payload = {
                transactionHash: unstakeResp?.transactionHash,
                rpc_url: netWorkUrl.url,
                explorerUrl: netWorkUrl?.blockExplorerUrls?.[0],
                receiptStatus: unstakeResp?.status ? "success" : "failed",
                blockchain: "ethereum",
                transType: 'unstake',
                user: user?._id,
                tokenA: { ...stake, name: stake?.tokenSymbol, symbol: stake?.tokenSymbol, tokenAmount: tokenAmount },
              }
              await dispatch(addTransaction(payload));
              await dispatch(getStakeList(filter));
              setUnstakeLoading(false);
              toastr.success("Unstake amount successfully!");
              setState({ tab: "tab2" })
              return;
            } else {
              setUnstakeLoading(false);
              toastr.error(response.message);
              return;
            }
          }
          dispatch(unstakeAmount({ _id: stake?._id }, callback));
        } else {
          setUnstakeLoading(false);
          return toastr.error("Something went wrong");
        }
      } else {
        setUnstakeLoading(false);
        return toastr.error("Request Id not found!");
      }
    } catch (error) {
      setUnstakeLoading(false);
      return toastr.error("Something went wrong!");
    }
  }
  return (
    <>
      <Selecttokenmod show={selecttoken} tokensList={stakeTokens} selectedToken={selectedTokenData} setSelectedToken={setSelectedTokenData} onHide={() => setselectoken(false)} />

      <Fade>
        <section className="total_tabbing">
          <div className="tabbing-herewrap">
            <div className="tab-frame dashboard-frame Sendrecieve">
              <div className="clearfix">
                <div className="label-wrap">
                  <input
                    type="radio"
                    name="tab"
                    id="tab1"
                    checked={state.tab == "tab1"}
                    onClick={() => setState({ tab: "tab1" })}
                  />
                  <label for="tab1">Stake</label>
                </div>

                <div className="label-wrap">
                  <input
                    type="radio"
                    name="tab"
                    id="tab2"
                    checked={state.tab == "tab2"}
                    onClick={() => setState({ tab: "tab2" })}
                  />
                  <label for="tab2">Unstake</label>
                </div>
              </div>
              {state.tab == "tab1" && (
                <Fade>
                  <div className="Liquidity-main position-relative">
                    <div className="d-flex justify-content-center">
                      {addStakeLoading &&
                        <Spinner style={{ color: "#fff" }} />
                      }
                    </div>
                    <div className="safe_secure d-flex align-items-center">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 14.9978C8 14.9978 13.9991 12.9981 13.9991 7.9989"
                            stroke="#1ABC7B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.9991 7.99891V1.99984C13.9991 1.99984 11.9994 1 8 1"
                            stroke="#1ABC7B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.00004 14.9978C8.00004 14.9978 2.00098 12.9981 2.00098 7.9989"
                            stroke="#1ABC7B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.00098 7.99891V1.99984C2.00098 1.99984 4.00067 1 8.00004 1"
                            stroke="#1ABC7B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9994 3.99951C8.00002 6.99904 7.00018 10.9984 7.00018 10.9984C7.00018 10.9984 6.00033 9.80501 5.00049 8.99873"
                            stroke="#1ABC7B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <p>Safe & secured</p>
                    </div>
                    <Row>
                      <Col lg={6} md={6} sm={12}>
                        <div className="staking_form">

                          <div className="Staking_head text-start mb-5 mt-2">
                            <h5>Stake Ethereum</h5>
                            <p>
                              1000000                  Deposit ETH and use ETHx in DeFi to earn staking
                              rewards while exploring additional reward
                              opportunities.
                            </p>
                          </div>

                          <Form className="form_stack w-100">
                            <FormGroup className="input_here_wrap">
                              <div className="input_label">
                                <p>Balance</p>
                              </div>

                              <div className="input_wrap">
                                <Input
                                  type="number"
                                  min={0}
                                  name="tokenAmount"
                                  onChange={(e) => setTokenAmount(e.target.value)}
                                  id="exampleToken"
                                  placeholder="0.00"
                                />

                                <div className="max-here">
                                  <Button
                                    className="btn-click"
                                    onClick={() => setselectoken(true)}
                                  >
                                    <img
                                      src={selectedTokenData?.firstToken?.icon}
                                      alt=""
                                      width="30px"
                                      className="img-fluid"
                                    />
                                    <p>{selectedTokenData?.firstToken?.symbol || "Select"}</p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                    >
                                      <path
                                        d="M21.625 11.625C21.8542 11.8542 21.9688 12.1458 21.9688 12.5C21.9688 12.8542 21.8542 13.1458 21.625 13.375L15.875 19.125C15.75 19.25 15.6146 19.3387 15.4688 19.3912C15.3229 19.4429 15.1667 19.4687 15 19.4687C14.8333 19.4687 14.6717 19.4375 14.515 19.375C14.3592 19.3125 14.2292 19.2292 14.125 19.125L8.375 13.375C8.14584 13.1458 8.03125 12.8542 8.03125 12.5C8.03125 12.1458 8.14584 11.8542 8.375 11.625C8.60417 11.3958 8.89583 11.2812 9.25 11.2812C9.60417 11.2812 9.89584 11.3958 10.125 11.625L15 16.5L19.875 11.625C20.1042 11.3958 20.3958 11.2812 20.75 11.2812C21.1042 11.2812 21.3958 11.3958 21.625 11.625Z"
                                        fill="#EEEEEE"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </FormGroup>
                            {selectedTokenData?.firstToken != null && (
                              <FormGroup className="input_here_wrap mt-5">
                                <div className="input_label mb-1">
                                  <p>Enter {selectedTokenData?.firstToken.symbol} amount</p>
                                  <p>
                                    My {selectedTokenData?.firstToken.symbol}x : {parseFloat(Number(tokenBalance).toFixed(4))}
                                  </p>
                                </div>

                                <div className="input_wrap">
                                  <Input
                                    type="number"
                                    readOnly={selectedTokenData?.firstToken == null ? true : false}
                                    name="tokenAmountForCal"
                                    id="exampleTokenAmountForCal"
                                    placeholder="0.00"
                                    onChange={(e) => { settokenAMountForCal(e.target.value) }}
                                  />

                                  <div className="max-here">
                                    <p className="max_here_txt" onClick={() => settokenAMountForCal(tokenAmount)}>MAX</p>
                                  </div>
                                </div>
                              </FormGroup>
                            )}
                            <div className="you_willgetpart">
                              {selectedTokenData?.firstToken != null && (
                                <div className="youwill_get d-flex align-itmes-center justify-content-between">
                                  <h6>You will get</h6>
                                  <p>
                                    <img
                                      src={selectedTokenData?.firstToken?.icon}
                                      alt=""
                                      width="15px"
                                      className="img-fluid"
                                    />
                                    {(Number(tokenAmountCal) + ((Number(tokenAmountCal) * Number(settings?.stakeContract?.roi)) / 100)) - (((Number(tokenAmountCal) + ((Number(tokenAmountCal) * Number(settings?.stakeContract?.roi)) / 100)) * Number(settings?.stakeContract?.StakeFee)) / 100)} {selectedTokenData?.firstToken.symbol}x
                                  </p>
                                </div>
                              )}
                              <ul>
                                <li>
                                  <p>ROI</p>
                                  <p>{settings?.stakeContract?.roi}%</p>
                                </li>
                                <li>
                                  <p>Stake Fee</p>
                                  <p>{settings?.stakeContract?.StakeFee}%</p>
                                </li>
                                <li>
                                  <p>Lock Time Period</p>
                                  <p>{lockTime || 0}</p>
                                </li>
                              </ul>
                            </div>

                            <Button
                              className="big-add-token Supply-btn py-3"
                              onClick={handleSubmit}
                              disabled={addStakeLoading}
                            >
                              <p>Stake Amount</p>
                            </Button>
                          </Form>
                        </div>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <div className="liqudity_img">
                          <img
                            src="/images/staking.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Fade>
              )}
              {state.tab == "tab2" && (
                <Fade>
                  <>
                    <>
                      <div className="Liquidity-main">
                        <div className="d-flex justify-content-center">
                          {unstakeLoading &&
                            <Spinner style={{ color: "#fff" }} />
                          }
                        </div>
                        <div class="heading_Sendrec">
                          <h3>Unstake Token</h3>
                        </div>

                        <div className="KYC-table">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Unskate</th>
                                <th>Id</th>
                                <th>Symbol</th>
                                <th>Transaction Hash</th>
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {stakeList?.length > 0 && stakeList?.map((stake) => {
                                return (
                                  <tr>
                                    <td>
                                      {stake?.endTime > moment().unix() ?
                                        `At ${moment.unix(stake?.endTime).format("YYYY-MM-DD HH:mm:ss")}`
                                        :
                                        <Button
                                          className="big-add-token Supply-btn py-3"
                                          onClick={handleUnstake(stake)}
                                          disabled={unstakeLoading}
                                        >
                                          <p>Unsake Amount</p>
                                        </Button>
                                      }
                                    </td>
                                    <td>{stake?.reqId}</td>
                                    <td>{stake?.tokenSymbol}</td>
                                    <td>{stake?.transactionHash?.slice(0, 12)}.....{stake?.transactionHash?.slice(-12)}</td>
                                    <td>{stake?.tokenAmount}</td>
                                    <td>{stake?.status}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </>
                  </>
                </Fade>
              )}
            </div>
          </div>
        </section>
      </Fade>
    </>
  );
}
