import React from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MyRouts from "./Routers/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css";
import { Provider } from "react-redux";
import store from "store";
import { MetaMaskProvider } from "utils/metamask";
import { FundsProvider } from "utils/funds";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <FundsProvider>
          <MetaMaskProvider>
            <MyRouts />
          </MetaMaskProvider>
        </FundsProvider>
      </Provider>
    </div>
  );
}

export default App;
