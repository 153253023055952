import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Buycelltab from "Pages/Profile/Dashtableview/Buycelltab";

export default function SwapTokensModal(props) {
  const {selectedPair} = props;
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="buy_mod"
    >
      <Modal.Body>
        <div className="mystery-modal-data">
        <Button className="closebtn" onClick={props.onhide}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59z"/></svg>
              </Button>
         <Buycelltab onFinish={props.onFinish} onHide={props.onhide} disableSelection={true} preSelectedPair={selectedPair}/>
        </div>
      </Modal.Body>
    </Modal>
  );
}
