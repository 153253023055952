import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
// import Transacsubmit from "Component/Modals/Transacsubmitmmod";
import { useSelector, useDispatch } from "react-redux";
import Selecttokenmod from "Component/Modals/Selecttokenmod";
import toastr from "toastr";
import useWallet from "Hooks/useWallet";
import Web3Intraction from "utils/web3Intraction";
import { addTransaction } from "store/actions";
import { Spinner } from "react-bootstrap";
import { WETH_TOKEN_ADDRESS } from "helpers/constants";

const Buycelltab = ({ preSelectedPair, ...props }) => {
  const { account } = useWallet();
  const dispatch = useDispatch();
  const { settings } = useSelector(({ Settings }) => Settings);
  const { user } = useSelector(({ Login }) => Login);
  const { loading, pairsList } = useSelector(({ Crypto }) => Crypto);
  const { AUDLoading } = useSelector(({ Transaction }) => Transaction);
  // const [Transacsubmitmmod, setTransacsubmitmmod] = useState(false);
  const [state, setState] = useState({ tab: "tab1" });
  const [showTokenModal, setShowTokenModal] = useState({
    show: false,
    tokenModalNum: 1,
  });
  const [selectedTokenData, setSelectedTokenData] = useState({
    firstToken: null,
    secondToken: null,
  });
  const [fields, setFields] = useState({
    fromAmount: 0,
    toAmount: 0,
  });
  const [tokenBalance, setTokenBalance] = useState({
    tokenABalance: 0,
    tokenBBalance: 0,
  });
  const [inLoading, setInLoading] = useState(false);

  useEffect(() => {
    setSelectedTokenData({
      firstToken: preSelectedPair?.primaryToken,
      secondToken: preSelectedPair?.secondryToken,
    });
  }, [preSelectedPair]);

  const onChangeTokenValues = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    (async () => {
      if (settings) {
        const web3Intraction = new Web3Intraction("ethereum", settings);
        /*Get tokens balance */
        if (
          selectedTokenData && selectedTokenData.firstToken?.contractAddress &&
          selectedTokenData.firstToken?.contractAddress != WETH_TOKEN_ADDRESS &&
          account
        ) {
          const tokenABalance = await web3Intraction.getTokenPrice(
            selectedTokenData.firstToken.contractAddress,
            account,
            selectedTokenData.firstToken.tokenDecimals
          );
          setTokenBalance((prev) => ({
            ...prev,
            tokenABalance: tokenABalance,
          }));
        }

        if (
          selectedTokenData && selectedTokenData.secondToken?.contractAddress &&
          selectedTokenData.secondToken?.contractAddress !=
            WETH_TOKEN_ADDRESS &&
          account
        ) {
          const tokenBBalance = await web3Intraction.getTokenPrice(
            selectedTokenData.secondToken.contractAddress,
            account,
            selectedTokenData.secondToken.tokenDecimals
          );
          setTokenBalance((prev) => ({
            ...prev,
            tokenBBalance: tokenBBalance,
          }));
        }
        /*End Get tokens balance */
      }
    })();
  }, [selectedTokenData, settings, account]);

  useEffect(() => {
    (async () => {
      try {
        if (
          fields?.fromAmount &&
          selectedTokenData?.firstToken &&
          selectedTokenData?.secondToken
        ) {
          const web3Instance = new Web3Intraction("ethereum", settings);
          const tokenbAmount = await web3Instance.getConverterPrice(
            selectedTokenData.firstToken,
            selectedTokenData.secondToken,
            fields.fromAmount,
            selectedTokenData.firstToken?.tokenDecimals
          );
          setFields((prev) => ({ ...prev, toAmount: tokenbAmount }));
        }
      } catch (e) {
        console.log("error while conversion", e);
      }
    })();
  }, [JSON.stringify(selectedTokenData), fields.fromAmount]);

  const onHideTokenModal = (show) => {
    setShowTokenModal({
      show: show,
      tokenModalNum: 0,
    });
  };

  const handleShowTokenModal = (show, modalNum) => {
    return;
    if (modalNum === 2 && !selectedTokenData?.firstToken) {
      toastr.error("Please select first token!");
      return;
    }
    setShowTokenModal({
      show: show,
      tokenModalNum: modalNum,
    });
  };

  const onBuyToken = async (e) => {
    e.preventDefault();
    try {
      if (!selectedTokenData?.firstToken) {
        toastr.error("Please select token A");
        return;
      }
      if (!selectedTokenData?.secondToken) {
        toastr.error("Please select token B");
        return;
      }
      if (!fields?.fromAmount) {
        toastr.error("Please enter first token amount!");
        return;
      }
      if (!fields?.toAmount) {
        toastr.error("Token B amount is  missing!");
        return;
      }
      if (!settings) {
        toastr.error("Settings are missing!");
        return;
      }
      if (!user?._id) {
        toastr.error("User id is required!");
        return;
      }

      if (!account) {
        toastr.error("Please connect your metamask!");
        return;
      }

      if (
        selectedTokenData.firstToken?.contractAddress !== WETH_TOKEN_ADDRESS
      ) {
        if (Number(fields.fromAmount) > Number(tokenBalance.tokenABalance)) {
          return toastr.error("You don't have enought token!");
        }
      }
      setInLoading(true);
      const web3Instance = new Web3Intraction("ethereum", settings);
      const netWorkUrl = web3Instance.getNetworkUrl();
      let reciept = null;

      if (selectedTokenData.firstToken.contractAddress === WETH_TOKEN_ADDRESS) {
        reciept = await web3Instance.buyTokenUsingSwap(
          selectedTokenData.firstToken,
          selectedTokenData.secondToken,
          fields.toAmount,
          fields.fromAmount
        );
      } else {
        reciept = await web3Instance.swapTokenToEth(
          selectedTokenData.firstToken,
          selectedTokenData.secondToken,
          fields.fromAmount,
          fields.toAmount
        );
      }

      if (reciept?.hash) {
        const payload = {
          transactionHash: reciept.transactionHash,
          rpc_url: netWorkUrl.url,
          explorerUrl: netWorkUrl?.blockExplorerUrls?.[0],
          receiptStatus: reciept?.status ? "success" : "failed",
          blockchain: "ethereum",
          transType: "swap",
          user: user._id,
          tokenA: {
            ...selectedTokenData.firstToken,
            tokenAmount: fields.fromAmount,
          },
          tokenB: {
            ...selectedTokenData.secondToken,
            tokenAmount: fields.toAmount,
          },
        };
        dispatch(
          addTransaction(payload, () => {
            // toastr.success("Transaction successfully completed!")
            props?.onHide && props.onHide();
            props?.onFinish && props.onFinish(payload);
          })
        );
      }
    } catch (error) {
      toastr.error(typeof error !== "object" ? error : "Something went wrong!");
      setInLoading(false);
    } finally {
      setInLoading(false);
    }
  };

  const onClickSwapToken = (e) => {
    e.preventDefault();
    setSelectedTokenData((prev) => ({
      firstToken: prev.secondToken,
      secondToken: prev.firstToken,
    }));
    setFields((prev) => ({
      fromAmount: prev.toAmount,
      toAmount: prev.fromAmount,
    }));
  };

  return (
    <>
      {/* <Transacsubmit
        show={Transacsubmitmmod}
        onhide={() => setTransacsubmitmmod(false)}
      /> */}
      <Selecttokenmod
        show={showTokenModal?.show}
        onHide={() => onHideTokenModal(false)}
        tokenModalNum={showTokenModal?.tokenModalNum}
        tokensList={pairsList}
        selectedToken={selectedTokenData}
        setSelectedToken={setSelectedTokenData}
      />
      <section className="buycelltab pt-5">
        <div className="tab-frame buycelltab_frame">
          <div className="clearfix">
            {/* <input
              type="radio"
              name="tab"
              id="tab1"
              checked={state.tab == "tab1"}
              onClick={() => setState({ tab: "tab1" })}
            />
            <label for="tab1">Buy</label> */}

            {/* <input
              type="radio"
              name="tab"
              id="tab2"
              checked={state.tab == "tab2"}
              onClick={() => setState({ tab: "tab2" })}
            />
            <label for="tab2">Pool info</label> */}
          </div>

          {state.tab == "tab1" && (
            <div className="mt-3">
              <div className="buytop d-flex align-items-center justify-content-between my-4 pb-3">
                <div className="buyto_left d-flex align-items-center">
                  <div className="buytop_chainimg me-2">
                    <img
                      src={selectedTokenData?.firstToken?.icon}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="buytop_chain_content">
                    <h6>
                      {selectedTokenData?.firstToken?.symbol}/
                      {selectedTokenData?.secondToken?.symbol}
                    </h6>
                    <p>
                      {selectedTokenData?.firstToken?.name} /{" "}
                      {selectedTokenData?.secondToken?.name}
                    </p>
                  </div>
                </div>
                <div className="buyto_right buytop_chain_content">
                  <h6>{account?.slice(0, 10) + "..."}</h6>
                  <p>Metamask wallet</p>
                </div>
              </div>

              <Form className="position-relative">
                <div className="price-input_wrap bottom_dropdown">
                  <div className="left-input">
                    <FormGroup className="position-relative price_input">
                      <Input
                        type="number"
                        name="fromAmount"
                        id="exampleEmail"
                        placeholder="Price"
                        value={fields.fromAmount}
                        onChange={onChangeTokenValues}
                      />
                    </FormGroup>
                  </div>

                  <div className="right_dropdown">
                    <div className="dropdown_fillter">
                      <Button
                        className="drop_btn"
                        onClick={() => handleShowTokenModal(true, 1)}
                      >
                        <div className="icon_here">
                          <img
                            src={selectedTokenData?.firstToken?.icon}
                            alt=""
                            width="30px"
                            className="img-fluid"
                          />
                        </div>
                        <p>
                          {selectedTokenData?.firstToken?.symbol || "Select"}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M9.7485 3.99451L6 7.64851L2.2515 3.99451C2.18452 3.9291 2.09462 3.89247 2.001 3.89247C1.90738 3.89247 1.81747 3.9291 1.7505 3.99451C1.71807 4.02629 1.69231 4.06421 1.67472 4.10607C1.65713 4.14792 1.64807 4.19286 1.64807 4.23826C1.64807 4.28366 1.65713 4.32861 1.67472 4.37046C1.69231 4.41232 1.71807 4.45024 1.7505 4.48201L5.73825 8.37001C5.80828 8.43827 5.9022 8.47648 6 8.47648C6.09779 8.47648 6.19172 8.43827 6.26175 8.37001L10.2495 4.48276C10.2822 4.45097 10.3081 4.41295 10.3258 4.37096C10.3436 4.32896 10.3527 4.28384 10.3527 4.23826C10.3527 4.19268 10.3436 4.14756 10.3258 4.10557C10.3081 4.06358 10.2822 4.02556 10.2495 3.99376C10.1825 3.92835 10.0926 3.89172 9.999 3.89172C9.90538 3.89172 9.81547 3.92835 9.7485 3.99376V3.99451Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                      {/* <div className="drop_downcontent">
                        <ul>
                          <li>
                            <a href="">dddgdgd</a>
                          </li>

                          <li>
                            <a href="">dddgdgd</a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>

                <Button
                  className="updown_arrow"
                  onClick={(e) => onClickSwapToken(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.21445 2.64314L3.8335 5.02409L5.61921 5.02409L5.61921 9.19076L6.80969 9.19076L6.80969 5.02409L8.5954 5.02409M7.40492 10.9765L9.78588 13.3574L12.1668 10.9765L10.3811 10.9765L10.3811 6.8098L9.19064 6.8098L9.19064 10.9765L7.40492 10.9765Z"
                      fill="black"
                    />
                  </svg>
                </Button>
                <div className="price-input_wrap bottom_dropdown">
                  <div className="left-input">
                    <FormGroup className="position-relative price_input">
                      <Input
                        type="number"
                        name="toAmount"
                        id="exampleEmail"
                        placeholder="Price"
                        value={fields.toAmount}
                        onChange={onChangeTokenValues}
                      />
                    </FormGroup>
                    {/* <p className="balance_show">
                      Balance ~<span>2450.211</span>
                    </p> */}
                  </div>

                  <div className="right_dropdown">
                    <div className="dropdown_fillter">
                      <Button
                        className="drop_btn"
                        onClick={() => handleShowTokenModal(true, 2)}
                      >
                        <div className="icon_here">
                          <img
                            src={selectedTokenData?.secondToken?.icon}
                            alt=""
                            width="30px"
                            className="img-fluid"
                          />
                        </div>
                        <p>
                          {selectedTokenData?.secondToken?.symbol || "Select"}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M9.7485 3.99451L6 7.64851L2.2515 3.99451C2.18452 3.9291 2.09462 3.89247 2.001 3.89247C1.90738 3.89247 1.81747 3.9291 1.7505 3.99451C1.71807 4.02629 1.69231 4.06421 1.67472 4.10607C1.65713 4.14792 1.64807 4.19286 1.64807 4.23826C1.64807 4.28366 1.65713 4.32861 1.67472 4.37046C1.69231 4.41232 1.71807 4.45024 1.7505 4.48201L5.73825 8.37001C5.80828 8.43827 5.9022 8.47648 6 8.47648C6.09779 8.47648 6.19172 8.43827 6.26175 8.37001L10.2495 4.48276C10.2822 4.45097 10.3081 4.41295 10.3258 4.37096C10.3436 4.32896 10.3527 4.28384 10.3527 4.23826C10.3527 4.19268 10.3436 4.14756 10.3258 4.10557C10.3081 4.06358 10.2822 4.02556 10.2495 3.99376C10.1825 3.92835 10.0926 3.89172 9.999 3.89172C9.90538 3.89172 9.81547 3.92835 9.7485 3.99376V3.99451Z"
                            fill="white"
                          />
                        </svg>
                      </Button>
                      {/* <div className="drop_downcontent">
                        <ul>
                          <li>
                            <a href="">dddgdgd</a>
                          </li>

                          <li>
                            <a href="">dddgdgd</a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="buyselll_btn">
                  <Button
                    onClick={onBuyToken}
                    className="connect_wallet_cta w-100"
                  >
                    {(inLoading || AUDLoading) && (
                      <Spinner
                        size="sm"
                        className="me-2"
                        style={{ color: "#fff" }}
                      />
                    )}{" "}
                    Buy
                  </Button>
                </div>
              </Form>
            </div>
          )}

          {state.tab == "tab2" && (
            <div className="mt-3">
              <div className="poolinfo_here">
                <h4>UNISWAP V2 POOL INFO</h4>
                <ul>
                  <li>
                    <p>Total liquidity:</p>
                    <p>$4.23M</p>
                  </li>
                  <li>
                    <p>Pooled WETH:</p>
                    <p>1.15K</p>
                  </li>
                  <li>
                    <p>Pooled DEXT:</p>
                    <p>3.60M</p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Buycelltab;
