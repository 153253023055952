import React, { useRef, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useDispatch, useSelector } from "react-redux";
// import { getTradeToken } from "store/actions";

const hotpairdata = [
  {
    hotpairname: "Flash",
    isEnabled: true,
  },
  {
    hotpairname: "PEPE",
    isEnabled: false,
  },
  {
    hotpairname: "BOTX",
    isEnabled: false,
  },
  {
    hotpairname: "BITCOIN",
    isEnabled: false,
  },

  {
    hotpairname: "Flash",
    isEnabled: false,
  },
  {
    hotpairname: "PEPE",
    isEnabled: false,
  },
  {
    hotpairname: "BOTX",
    isEnabled: false,
  },
  {
    hotpairname: "BITCOIN",
    isEnabled: false,
  },

  {
    hotpairname: "Flash",
    isEnabled: false,
  },
  {
    hotpairname: "PEPE",
    isEnabled: false,
  },
  {
    hotpairname: "BOTX",
    isEnabled: false,
  },
  {
    hotpairname: "BITCOIN",
    isEnabled: false,
  },
];

const Hotpairs = (props) => {
  const mymarquee = useRef();
  return (
    <Fade>
      <section className="marquess_Lsit">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between">
            <div className="hotpair_head d-flex align-items-center">
              <span className="me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="21"
                  viewBox="0 0 15 21"
                  fill="none"
                >
                  <path
                    d="M6 1.66797L6.625 0.835938C6.78125 0.625 7.03125 0.5 7.29297 0.5C7.75 0.5 8.125 0.875 8.125 1.33203V2.19922C8.125 2.71094 8.33594 3.20312 8.70703 3.55469L12.0156 6.71094C13.9219 8.53125 15 11.0547 15 13.6914C15 17.4531 11.9531 20.5 8.19141 20.5H7.5C3.35938 20.5 0 17.1406 0 13V12.8516C0 10.9453 0.757813 9.11719 2.10547 7.76953L2.24219 7.63281C2.40625 7.46875 2.63281 7.375 2.86719 7.375C3.35547 7.375 3.75 7.76953 3.75 8.25781V11.75C3.75 13.1289 4.87109 14.25 6.25 14.25C7.62891 14.25 8.75 13.1289 8.75 11.75V11.5977C8.75 10.8945 8.46875 10.2188 7.97266 9.72266L6.46484 8.21484C5.52734 7.27734 5 6 5 4.67188C5 3.58984 5.35156 2.53125 6 1.66797Z"
                    fill="white"
                  />
                </svg>
              </span>
              <h4>Hot Pairs</h4>
            </div>

            <marquee
              ref={mymarquee}
              className="marquee w-100"
              direction="left"
              onMouseOver={() => mymarquee.current?.stop()}
              onMouseOut={() => mymarquee.current?.start()}
              behavior="alternate"
              loop="infinite"
              scrollamount="2"
            >
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center">
                {props?.tradeTokens?.length > 0 && props?.tradeTokens?.map((item, idx) => {
                  const isEnabled = item.isEnabled || false;
                  return (
                    <li className="px-3">
                      <Button
                        variant="transparent"
                        className="px-1 border-0 py-0 text-white"
                      >
                        <div className="d-flex align-items-center CardCstm gap-2">
                          <p
                            className={
                              isEnabled
                                ? "text-blue m-0 text-uppercase"
                                : "text-white m-0 text-uppercase"
                            }
                          >
                            #{idx + 1}
                          </p>

                          {/* {isEnabled ? (
                            <>
                              <span className="ms-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="11"
                                  viewBox="0 0 12 11"
                                  fill="none"
                                >
                                  <path
                                    d="M12 11L6 6.89552L0 11L2.39832e-08 8.98881L6 4.88433L12 8.98881L12 11ZM12 6.11567L6 2.01119L5.8245e-08 6.11567L8.22283e-08 4.10448L6 -8.82125e-07L12 4.10448L12 6.11567Z"
                                    fill="#17C671"
                                  />
                                </svg>
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="ms-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="11"
                                  viewBox="0 0 12 11"
                                  fill="none"
                                >
                                  <path
                                    d="M9.53674e-07 -5.24537e-07L6 4.10448L12 0L12 2.01119L6 6.11567L8.65762e-07 2.01119L9.53674e-07 -5.24537e-07ZM7.40174e-07 4.88433L6 8.98881L12 4.88433L12 6.89552L6 11L6.52261e-07 6.89552L7.40174e-07 4.88433Z"
                                    fill="#EF646B"
                                  />
                                </svg>
                              </span>
                            </>
                          )} */}
                          <img src={item.icon} width={25} height={25} ></img>
                          <p
                            className={
                              isEnabled
                                ? "text-blue m-0 text-uppercase"
                                : "text-white m-0 text-uppercase"
                            }
                          >
                            {item.symbol}
                          </p>
                        </div>
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </marquee>

            <div className="floki_show d-flex align-items-center justify-content-end">
              <div className="floki_img me-2">
                <img src="/images/floki.png" alt="" className="img-fluid" />
              </div>
              <p>FLOKI</p>
            </div>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Hotpairs;
