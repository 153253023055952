import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "reactstrap";

export default function Selecttokenmod(props) {
  const { tokensList, setSelectedToken } = props;
  const [uniqueTokens, setUniqueTokens] = useState([]);
  
  useEffect(() => {
    if (tokensList?.length) {
        const uniqueList = [];
        tokensList.forEach((item) => {
          const findPrimary = uniqueList.find((uItem) => uItem?.symbol === item?.symbol);
          if (!findPrimary) {
            uniqueList.push({ ...item, pairId: item._id })
          }
        });
        setUniqueTokens(uniqueList);
    }
  }, [tokensList]);

  const onSelectTokenHandler = (row) => {
    const findPairsData = tokensList?.find((item) => (item?._id === row?.pairId))
    if (findPairsData) {
        setSelectedToken(prev => ({ ...prev, firstToken: row }));
      props?.onHide();
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="trasaxction-mod selectoken_mod"
    >
      <Button className="close-btn" onClick={props.onHide}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.75806 17.2431L12.0011 12.0001M17.2441 6.75708L12.0001 12.0001M12.0001 12.0001L6.75806 6.75708M12.0011 12.0001L17.2441 17.2431"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Button>
      <Modal.Body>
        <div className="select_token_modal">
          <h6 className="green-col">Select a token</h6>
          <Form className="token_search">
            <div className="select_token_here">
              <ul>
                {(uniqueTokens || [])?.map((item, index) => {
                  return (
                    <li key={index} onClick={() => onSelectTokenHandler(item)}>
                      <div className="token_search_list">
                        <div className="token-icon">
                          <div className="token_icon">
                            <img src={item.icon} alt="" className="img-fluid" />
                          </div>
                          <p><strong>{item.name}</strong> {item.symbol}</p>
                        </div>
                      </div>
                    </li>
                  );
                })}

              </ul>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
