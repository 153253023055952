import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { socket } from "socket";

//component
import Buycelltab from "./Buycelltab";
import RealtimeEmulation from "./light-graph/index";

///store
import { getCandlesList, getTradeDetails } from "store/actions";

const Leveragetrading = () => {
  const dispatch = useDispatch();
  const { tradeDetails,  } = useSelector((state) => state.Crypto);
  const { settings } = useSelector((state) => state.Settings);

  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    dispatch(getTradeDetails(id));
    dispatch(getCandlesList({ tradeId: id }));
  }, [id]);

  useEffect(() => {
    socket.on("connect", (res) => {
      console.log(res, "yes it connected");
    });
    socket.on("NewCandleAdded", (res) => {
      dispatch(getCandlesList({ tradeId: id }));
    });
  }, []);

  
  return (
    <>
      <div className="trade_fillter d-flex justify-content-between my-4">
        <div className="tradehead_side text-start">
          <h5>Trade Listing</h5>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable .
          </p>
        </div>
      </div>
      <section className="Dashtableview comon_pad">
        <Row>
          <Col lg={8} md={6} sm={12}>
            <div className="graph_wrapping">
              <div className="single_graph_side">
                <RealtimeEmulation symbol={tradeDetails?.symbol || ""}/>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Buycelltab tradeTokenDetails={tradeDetails || null} settings={settings} />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Leveragetrading;
