import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";

//STORE
import { getTradeTokenRequest } from "store/actions";

//HELPER
import {
  WETH_TOKEN_ADDRESS,
  converCryptoToDollar,
} from "helpers/constants";
import Web3Intraction from "utils/web3Intraction";

const Pairpricechart = (props) => {
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const { settings } = useSelector(({ Settings }) => Settings);
  const [newPairList, setNewPairList] = useState(tradeTokens);

  useEffect(() => {
    setNewPairList(tradeTokens)
    getAmountsOut(tradeTokens, settings);
  }, [tradeTokens, settings]);

  async function getAmountsOut(tradeTokens, settings) {
    if (tradeTokens.length > 0 && settings) {
      const web3Intraction = new Web3Intraction("ethereum", settings);
      web3Intraction?.PROVIDER?.on("block", async (blockNumber) => {
        // Emitted on every block change
        let tradeToken = JSON.parse(JSON.stringify(tradeTokens));
        for (let i = 0; i < tradeToken.length; i++) {
          try {
            let value = await web3Intraction.getConverterPrice(
              {
                contractAddress:
                  tradeToken[i].networkMode == "mainnet"
                    ? tradeToken[i].contractAddress
                    : tradeToken[i].testnetContractAddress,
              },
              { contractAddress: WETH_TOKEN_ADDRESS },
              1,
              tradeToken[i].decimals
            );
            tradeToken[i].currentPrice = value || 0;
            tradeToken[i].DollarAmount = await converCryptoToDollar(
              value,
              "ETH",
              8
            );
          } catch (error) {
            console.error(error, "<====errr in loop");
            tradeToken[i].currentPrice = 0;
            tradeToken[i].DollarAmount = 0;
          }
        }
        setNewPairList(tradeToken); // Set the value in the component state
      });
    }
  }

  return (
    <section className="Opendertable-table pairprice_chart mt-3">
      <div className="shared_head d-flex justify-content-between align-items_center mb-3">
        <h3 className="card_heading">{props?.heading}</h3>
      </div>

      <div className="pairshared_table">
        <Table responsive>
          <thead>
            <tr>
              <th>Token Name</th>
              <th>Price</th>
              <th>Price USD</th>
            </tr>
          </thead>
          <tbody>
            {newPairList.length > 0 &&
              newPairList?.map((pair) => {
                return (
                  <tr>
                    <td>
                      <Link to={`/leverage/${pair?._id}`}>
                        <div className="table_assets d-flex align-items-center">
                          <div className="table_assets_img me-2">
                            <img
                              src={pair?.icon}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="table_assets_content">
                            <p>
                              <strong>{pair?.name}</strong>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td>{pair?.currentPrice || 0} Eth</td>
                    <td>${pair?.DollarAmount || 0}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default Pairpricechart;
