import React from "react";
import { MetaMaskContext } from "../utils/metamask";

export default function useWallet() {
    const context = React.useContext(MetaMaskContext);

    if (context === undefined) {
        throw new Error(
            "useWallet hook must be used with a WalletProvider component"
        );
    }

    return context;
}
