import React from "react";
import { Button } from "react-bootstrap";
import { Table, Form, FormGroup, Label, Input, FormText } from "reactstrap";

const Tradehistorytable = () => {
  return (
    <section className="Opendertable-table">
      <div className="calender_Search">
        <ul>
          <li>1 Day</li>
          <li>1 Week</li>
          <li>1 Month</li>
          <li>3 Months</li>
        </ul>
        <Form>
          <FormGroup className="position-relative">
            <Input
              type="datetime"
              name="email"
              id="exampleEmail"
            />
            <Button>Search</Button>
          </FormGroup>
        </Form>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Time</th>
            <th>Symbol</th>
            <th>Side</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Fee</th>
            <th>Realized Profit</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6].map(() => {
            return (
              <tr>
                <td>2022-07-22 11:20:00</td>
                <td className="trde_symbol">
                  <p>
                    <strong>BNBUSD</strong>
                  </p>
                  <p>Perpetual</p>
                </td>

                <td>
                  <p className="col-red">Sell</p>
                </td>
                <td>258.22</td>
                <td>256.32USDT</td>
                <td>0.011155455 USDT</td>
                <td>14.22566665548 USDT</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export default Tradehistorytable;
