import React, { useState, useEffect, useMemo } from "react";
import toastr from "toastr";
import { convertHexToString, convertNumberToHex } from "./web3Intraction";
import { ethers } from "ethers";
import { getNetworkUrl } from "helpers/constants";
import Web3 from "web3";

export const MetaMaskContext = React.createContext(null);

export const MetaMaskProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [account, setaccount] = useState("");
  const [chainId, setchainId] = useState();
  const [blockchain, setBlockchain] = useState("ETH");

  // Init Loading
  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      addWalletListener();
      addNetworkListener();

      if (localStorage.getItem("isMetamaskConnected") === "yes") {
        checkConnection();
      }
    } else {
      setIsActive(false);
    }
  }, []);

  const checkConnection = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.listAccounts();

      const address = Array.isArray(accounts) ? accounts[0] : "";
      // Set User account into state
      if (address) {
        setIsActive(true);
        setaccount(address || "");
      }
    } catch (e) {
      console.log("error while connection check", e);
    }
  };

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }
  // Connect to MetaMask wallet
  const connect = (settings) => {
    return new Promise(async (resolve, reject) => {

      if (settings && settings?.blockchain) {
        let networkUrl = null;
        try {
          networkUrl = await getNetworkUrl("ethereum", settings);
     
          var params = {
            chainId: convertNumberToHex(networkUrl?.chainId),
          };
          await switchChain(params);
          const walletResponse = await connectWallet();
          if (walletResponse?.address) {
            setaccount(walletResponse.address);
            setIsActive(true);
          }

          resolve(walletResponse);
        } catch (error) {
          error = error.reason || error.message || error.data || error;
          console.log("error in connect");

          reject(error);
          if (error.code === 4902) {
            try {
              console.log(networkUrl);
              const provider = new ethers.providers.Web3Provider(
                window.ethereum
              );
              await provider.send("wallet_addEthereumChain", [
                {
                  chainName: networkUrl.chainName,
                  chainId: convertNumberToHex(networkUrl?.chainId),
                  nativeCurrency: networkUrl.nativeCurrency,
                  rpcUrls: [networkUrl.url],
                },
              ]);
            } catch (error) {
              console.log("error in add chain");
            }
          } else {
            if (isMobileDevice()) {
              try {
                console.log(networkUrl);
                const provider = new ethers.providers.Web3Provider(
                  window.ethereum
                );
                await provider.send("wallet_addEthereumChain", [
                  {
                    chainName: networkUrl.chainName,
                    chainId: convertNumberToHex(networkUrl?.chainId),
                    nativeCurrency: networkUrl.nativeCurrency,
                    rpcUrls: [networkUrl.url],
                  },
                ]);
              } catch (error) {
                console.log("error in add chain");
              }
            }
          }
        }
      }
    });
  };

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    setaccount("");
    setIsActive(false);
    localStorage.setItem("isMetamaskConnected", "no");
    if (window.ethereum && window.ethereum.disconnect) {
      window.ethereum.disconnect();
    }
  };

  const switchNetwork = async (chainId) => {
    const params = {
      chainId: convertNumberToHex(chainId),
    };
    await switchChain(params);
  };

  async function addWalletListener() {
    window.ethereum.on("accountsChanged", async (accounts) => {
      if (accounts.length > 0) {
        setIsActive(true);
        setaccount(accounts[0]);
      } else {
        setIsActive(false);
        setaccount("");
      }
    });
  }

  async function addNetworkListener() {
    console.log("addNetworkfunction");
    try {
      setchainId(
        convertHexToString(
          await window.ethereum.request({
            method: "eth_chainId",
            params: [],
          })
        )
      );
      window.ethereum.on("chainChanged", (res) => {
        setchainId(convertHexToString(res));
      });
    } catch (e) {
      console.log("e", e);
    }
  }

  const values = useMemo(
    () => ({
      isActive,
      account,
      connect,
      disconnect,
      switchNetwork,
      chainId,
      setBlockchain,
      blockchain,
    }),
    [isActive, account, chainId, blockchain]
  );

  return (
    <MetaMaskContext.Provider value={values}>
      {children}
    </MetaMaskContext.Provider>
  );
};

export const signMetamask = async () => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    try {
      const message = "Hello, this is a signed message!";
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();

      if (accounts.length > 0) {
        const signature = await web3.eth.personal.sign(
          message,
          accounts[0],
          ""
        );
        console.log("Signature:", signature);
        localStorage.setItem("metamaskSignature", signature);
        return signature;
      } else {
        console.error("No MetaMask accounts available.");
      }
    } catch (error) {
      console.error("Error signing message:", error);
    }
  }
};

export const verifySignature = async (signature) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    try {
      const message = "Hello, this is a signed message!";
      const signature = "..."; // Replace with the signature obtained from signing the message
      const web3 = new Web3(window.ethereum);
      return await web3.eth.accounts.recover(message, signature);
    } catch (error) {
      console.error("Error verifying signature:", error);
    }
  }
};

export const connectWallet = async () => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = { address: addressArray[0] };
      localStorage.setItem("isMetamaskConnected", "yes");
      await signMetamask();
      return obj;
    } catch (err) {
      return { address: "" };
    }
  } else {
    window.open("https://metamask.io/download.html", "_blank");
    toastr.error(
      "You must install Metamask, a virtual Ethereum wallet, in your browser."
    );

    return { address: "" };
  }
};

export const getCurrentWalletConnected = async () => {
  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (addressArray.length > 0) {
      return { address: addressArray[0] };
    }

    return { address: "" };
  } catch (err) {
    return { address: "" };
  }
};
export const onAccountChange = (callback) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on("accountsChanged", callback);
  }
};

export const getCurrentChain = () => {
  try {
    return window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
  } catch (err) {}
};

export const onChainChange = (callback) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on("chainChanged", callback);
  }
};
export const switchChain = (params) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [params],
    });
  }
};

export const addChain = (params) => {
  if (window.ethereum && window.ethereum.isMetaMask) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [params],
    });
  }
};
export const sendTransaction = (params) => {
  try {
    return window.ethereum.request({
      method: "eth_sendTransaction",
      params: [params],
    });
  } catch (err) {}
};
