import React from "react";
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { Slider } from "antd";

//helper
import { LEVERAGE_MARKS } from "helpers/constants";
import { ClickButton } from "Component/InputElements";

function BotForm({
  fields,
  handleChange,
  balance,
  handleSubmit,
  botLoading,
  query,
  handleClear,
  handleEdit
}) {
  return (
    <>
      <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="trigger_left">
          <p className="trigger_head">Buy</p>

          <div className="trigger_price">
            <FormGroup className="position-relative">
              <Label for="exampleInput">Trigger Price</Label>
              <Input
                type="number"
                name="buyPrice"
                id="trigger"
                placeholder="0.00"
                min={0}
                value={fields.buyPrice}
                onChange={handleChange}
              />
            </FormGroup>
            {/* <p className="sub_line">5% of LTP</p> */}
          </div>
        </div>

        <div className="place_order">
          <p>
            Place Order{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                />
              </svg>
            </span>
          </p>
        </div>

        <div className="trigger_right">
          <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Qty.</Label>
                  <Input
                    type="number"
                    name="tokenAmount"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.tokenAmount}
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Collateral</Label>
                  <Input
                    type="number"
                    name="totalPrice"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.collateral}
                    onChange={(e) => e.preventDefault()}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <p className="text-white pt-2"> Balance: {balance || 0}</p>
{
   query !== "edit" && !fields._id  && 
   <Slider
            marks={LEVERAGE_MARKS}
            name="leverage"
            trackBg="f00"
            defaultValue={1}
            min={1}
            max={5}
            onChange={(value) =>
              handleChange({
                target: {
                  name: "leverage",
                  value,
                },
              })
            }
            value={fields.leverage}
          />
}
          
        </div>
      </div>
      <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="trigger_left">
          <p className="trigger_head">Stoploss</p>

          <div className="trigger_price">
            <FormGroup className="position-relative">
              <Label for="exampleInput">Trigger Price</Label>
              <Input
                type="number"
                name="stopLoss"
                id="trigger"
                placeholder="0.00"
                min={0}
                value={fields.stopLoss}
                onChange={handleChange}
              />
            </FormGroup>
            {/* <p className="sub_line">5% of LTP</p> */}
          </div>
        </div>

        <div className="place_order">
          <p>
            Place Order{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                />
              </svg>
            </span>
          </p>
        </div>

        <div className="trigger_right">
          <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Qty.</Label>
                  <Input
                    type="number"
                    name="tokenAmount"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.tokenAmount}
                    onChange={(e) => e.preventDefault}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Collateral</Label>
                  <Input
                    type="number"
                    name="totalPrice"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.collateral}
                    onChange={(e) => e.preventDefault}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3">
        <div className="trigger_left">
          <p className="trigger_head">Target</p>

          <div className="trigger_price">
            <FormGroup className="position-relative">
              <Label for="exampleInput">Trigger Price</Label>
              <Input
                type="number"
                name="targetPrice"
                id="trigger"
                placeholder="0.00"
                min={0}
                value={fields.targetPrice}
                onChange={handleChange}
              />
            </FormGroup>
            {/* <p className="sub_line">5% of LTP</p> */}
          </div>
        </div>

        <div className="place_order">
          <p>
            Place Order{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                />
              </svg>
            </span>
          </p>
        </div>

        <div className="trigger_right">
          <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Qty.</Label>
                  <Input
                    type="number"
                    name="tokenAmount"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.tokenAmount}
                    onChange={(e) => e.preventDefault}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>
            <div className="Qty_wrap">
              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleInput">Collateral</Label>
                  <Input
                    type="number"
                    name="totalPrice"
                    id="trigger"
                    placeholder="0.00"
                    min={0}
                    value={fields.collateral}
                    onChange={(e) => e.preventDefault}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_footer pt-4 border-top border-light border-opacity-25 px-4 py-3">
        <Row>
          <Col lg={8} md={12} sm={12}>
            <p className="fotte_para">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
              explicabo optio nostrum sapiente natus pariatur iure. Error ab
              quam nulla minus eius, aspernatur, ratione perspiciatis odit,
              maiores necessitatibus ullam earum?
            </p>
          </Col>

          <Col lg={4} md={12} sm={12}>
            <div className="Btn_twice d-flex align-items-center justify-content-center gap-3">
              <ClickButton
                btnText={query === "edit" && fields?._id ? "Edit" : "Place"}
                className="place_btn"
                onClick={ query === "edit" && fields?._id ?  handleEdit  : handleSubmit}
                disabled={botLoading}
              />

              <Button
                className="cancel_btn"
                onClick={(e) =>
                  handleClear(e, query === "edit" && fields?._id ? "cancel" : "clear")
                }
              >
                {query === "edit" && fields?._id ? "Cancel" : "Clear"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BotForm;
