import React from "react";
import Dashtableview from "Pages/Profile/Dashtableview";
import Headerlogin from "Component/Header/Headerlogin";

export default function Tradelistingtheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Dashtableview/>
    </>
  );
}
