import React from "react";
import Dashboardwrap from "Pages/Profile";


export default function Dashboardtheme() {
  return (
    <>
      <Dashboardwrap/>
    </>
  );
}
