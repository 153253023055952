import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Stake Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* makeBotRequest({ payload: { user, callback } }) {
  try {


    const response = yield call(API.makeBotRequest, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.makeBotRequestFail(response.message));
    }
    yield put(ACTIONS.makeBotRequestSuccess(response));
  } catch (error) {

    console.log(error, "<=====error")
    yield put(ACTIONS.makeBotRequestFail("Internal Server Error"));
  }
}

function* editBotRequest({ payload: { user, callback } }) {
  try {
    const response = yield call(API.editBotRequest, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.editBotRequestFail(response.message));
    }
    yield put(ACTIONS.editBotRequestSuccess(response));
  } catch (error) {
    yield put(ACTIONS.editBotRequestFail("Internal Server Error"));
  }
}

function* cancelBotRequest({ payload: { user, callback } }) {
  try {
    const response = yield call(API.cancelBotRequest, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.cancelBotRequestFail(response.message));
    }
    yield put(ACTIONS.cancelBotRequestSuccess(response));
  } catch (error) {
    yield put(ACTIONS.cancelBotRequestFail("Internal Server Error"));
  }
}



function* getBotRequests({ payload }) {
  try {
    const response = yield call(API.getBotRequests, payload)
    if (response.status == "failure") {
      return yield put(ACTIONS.getBotRequestsFail(response.message))
    }

    yield put(ACTIONS.getBotRequestsSuccess(response))
  } catch (error) {
    yield put(ACTIONS.getBotRequestsFail("Internal Server Error"))
  }
}

export function* Order() {
  yield takeEvery(TYPES.MAKE_BOT_REQUEST, makeBotRequest);
  yield takeEvery(TYPES.EDIT_BOT_REQUEST, editBotRequest);
  yield takeEvery(TYPES.GET_BOT_REQUESTS, getBotRequests);
  yield takeEvery(TYPES.CANCEL_BOT_REQUEST, cancelBotRequest);
}

function* botsaga() {
  yield all([fork(Order)]);
}

export default botsaga;
