//Dependencies
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import toastr from "toastr";
import { Slider } from "antd";
import { Button, Form } from "reactstrap";

//Component and common functionality
import Transacsubmit from "Component/Modals/Transacsubmitmmod";
import useWallet from "Hooks/useWallet";
import Web3Intraction from "utils/web3Intraction1";
import {
  WETH_MAINNET_TOKEN_ADDRESS,
  WETH_TOKEN_ADDRESS,
  getNetworkUrl,
  LEVERAGE_MARKS,
} from "helpers/constants";
import { getTokenCurrentPrice, openOrder } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
var W3CWebSocket = require("websocket").w3cwebsocket;

//  https://api.coingecko.com/api/v3/simple/price?ids=DAI&vs_currencies=USD
const Buycelltab = (props) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("type");

  const wallet = useWallet();
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  //selector
  const { user } = useSelector((state) => state.Login);
  const { orderDetails } = useSelector((state) => state.Bot);

  //Manage all states
  const [Transacsubmitmmod, setTransacsubmitmmod] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [leverage, setLeverage] = useState(1);
  const [collateral, setCollateral] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [currentTokenPrice, setCurrentTokenPrice] = useState(0);
  const [tab, setTab] = useState("long");
  const [openOrderLoading, setOpenOrderLoading] = useState(false);
  const [fields, setfields] = useState({
    quantity: 0,
    collateral: 0,
    leverage: 1,
  });

  //Get account balance
  useEffect(() => {
    if (props.settings && wallet.account) {
      getBalance();
    }
  }, [props.settings, wallet]);

  const getBalance = async () => {
    try {
      const web3Intraction = new Web3Intraction("ethereum", props.settings);
      const amount = await web3Intraction.getBalance(wallet.account);
      setBalance(amount);
    } catch (error) {
      setBalance(0);
    }
  };

  //Handle values
  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setfields((prev) => ({ ...prev, [name]: value }));
  };
  const handleLeverage = (newValue) => {
    setLeverage(newValue);
  };

  const getTokenValue = async () => {
    try {
      const networkUrl = getNetworkUrl("ethereum", props?.settings);
      await wallet.switchNetwork(networkUrl?.chainId || "5");
    } catch (error) {
      return toastr.error("User reject request!");
    }
    const web3Intraction = new Web3Intraction("ethereum", props?.settings);
    if (props?.tradeTokenDetails && wallet.account) {
      const tokenBalance = await web3Intraction.getTokenPrice(
        props?.tradeTokenDetails?.networkMode == "testnet"
          ? props?.tradeTokenDetails?.testnetContractAddress
          : props?.tradeTokenDetails?.contractAddress,
        wallet.account,
        props?.tradeTokenDetails?.decimals
      );
      setTokenBalance(tokenBalance);
    }
  };

  //get token balance
  useEffect(() => {
    if (props?.settings && props?.tradeTokenDetails) {
      getTokenValue();
    }
  }, [props?.tradeTokenDetails, props?.settings, wallet]);

  //get token current price and calculate collateral
  useEffect(() => {
    if (fields.quantity != 0 && leverage && props?.tradeTokenDetails) {
      (async () => {
        if (props?.tradeTokenDetails && props.settings) {
          const networkUrl = getNetworkUrl("ethereum", props.settings);
          const provider = new ethers.providers.Web3Provider(
            window.ethereum,
            networkUrl
              ? {
                name: networkUrl.chainName,
                chainId: Number(networkUrl.chainId),
              }
              : "any"
          );
          // Address of the Uniswap V2 Router contract
          const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
          // Create a contract instance of the Uniswap V2 Router
          const router = new ethers.Contract(
            routerAddress,
            [
              "function getAmountsOut(uint256 amountIn, address[] memory path) public view returns (uint256[] memory)",
            ],
            provider
          );
          provider.on("block", async (blockNumber) => {
            // Emitted on every block change
            try {
              let value = await router.getAmountsOut(
                Number(props?.tradeTokenDetails?.decimals) != 18
                  ? 10 ** Number(props?.tradeTokenDetails?.decimals)
                  : ethers.utils.parseUnits("1", "ether"),
                // ethers.utils.parseUnits("1", "ether"),
                [
                  props?.tradeTokenDetails?.networkMode == "testnet"
                    ? props?.tradeTokenDetails?.testnetContractAddress
                    : props?.tradeTokenDetails?.contractAddress,
                  props?.tradeTokenDetails?.networkMode == "testnet"
                    ? WETH_TOKEN_ADDRESS
                    : WETH_MAINNET_TOKEN_ADDRESS,
                ]
              );
              // console.log(value[1].toString() / (10 ** 12), "value")
              const currentPrice = ethers.utils.formatEther(
                value[1].toString()
              );
              console.log(currentPrice, "currentPrice");
              const collateral =
                Number(fields.quantity) *
                Number(leverage) *
                Number(currentPrice);

              setCurrentPrice(currentPrice); // Set the value in the component state
              setCollateral(Number(collateral)?.toFixed(18)); // Set the value in the component state
            } catch (error) {
              console.error(error);
            }
          });
        }
      })();
    }
  }, [fields, leverage, props?.tradeTokenDetails, props?.settings]);

  // Curent token price socket
  useEffect(() => {
    const client = new W3CWebSocket("wss://api-pub.bitfinex.com/ws/2");
    console.log(props?.tradeTokenDetails, "tradeTokenDetails")
    client.onmessage = async (msg) => {
      const resp = JSON.parse(msg.data.toString());
      console.log(resp, "resp")
      if (resp[1] && resp[1].length == 10) {
        const currentPrice = resp[1][6];
        setCurrentTokenPrice(currentPrice);
      }
    };
    let msg = JSON.stringify({
      event: "subscribe",
      channel: "ticker",
      symbol: `t${props?.tradeTokenDetails &&
        props?.tradeTokenDetails.bitFinexSymbol &&
        props?.tradeTokenDetails?.bitFinexSymbol
        }`,
    });

    client.onopen = () => client.send(msg, "msg");
  }, [props?.tradeTokenDetails]);

  useEffect(() => {
    if (orderDetails && query) {
      setCollateral(orderDetails?.collateral);
      setLeverage(orderDetails?.leverage);
      setTab(orderDetails?.position);
      setfields((pre) => ({
        ...pre,
        ...orderDetails,
        quantity: orderDetails?.tokenAmount,
        tokenId: orderDetails.tokenId?._id,
        userId: orderDetails.userId?._id,
      }));
    }
  }, [JSON.stringify(orderDetails), query]);

  //open order
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenOrderLoading(true)
    if (fields.quantity <= 0) {
      setOpenOrderLoading(false)
      return toastr.error("Amount should be greater then zero!");
    }
    if (Number(fields.quantity) > Number(tokenBalance)) {
      setOpenOrderLoading(false)
      return toastr.error("You don't have enought token to open position!");
    }
    if (Number(collateral) > Number(balance)) {
      setOpenOrderLoading(false)
      return toastr.error(
        "you don't have enought balance for taking leverage!"
      );
    }

    if (Number(fields.stopLoss) > Number(currentTokenPrice) && tab == "long") {
      setOpenOrderLoading(false)
      return toastr.error("Stop loss should be less then current price");
    }

    if (Number(currentTokenPrice) > Number(fields.stopLoss) && tab == "short") {
      setOpenOrderLoading(false)
      return toastr.error("Stop loss should be greator then current price");
    }

    try {
      const web3Intraction = new Web3Intraction("ethereum", props?.settings);
      if (props?.tradeTokenDetails && wallet.account) {
        const callBack = async (response) => {
          // return
          if (response.data.length == 10) {
            try {
              const params = {
                collateral: collateral,
                tokenAddress:
                  props?.tradeTokenDetails?.networkMode == "testnet"
                    ? props?.tradeTokenDetails?.testnetContractAddress
                    : props?.tradeTokenDetails?.contractAddress,
                decimals: props?.tradeTokenDetails?.decimals,
                tokenAmount: fields.quantity,
                leverage: leverage,
                position: tab == "long" ? 0 : 1,
                currentTicker: response.data[6],
                reqId: Math.floor(100000 + Math.random() * 900000),
                userWallet: wallet.account,
              };
              const openPositionResp = await web3Intraction.openPosition(
                params
              );
              if (openPositionResp.status) {
                const callback = async (response) => {
                  if (response.status == "success") {
                    setOpenOrderLoading(false);
                    toastr.success("Open Position Successfully!");
                    naviagte("/positions");
                  } else {
                    setOpenOrderLoading(false);
                    return toastr.error(response.message);
                  }
                };
                if (fields.stopLoss != 0) {
                  params.stopLoss = fields?.stopLoss;
                }
                dispatch(
                  openOrder(
                    {
                      ...params,
                      position: params.position == 0 ? "long" : "short",
                      userId: user?._id,
                      tokenId: props.tradeTokenDetails._id,
                      status: "open",
                    },
                    callback
                  )
                );
              } else {
                setOpenOrderLoading(false);
                return toastr.error("Something went wrong");
              }
            } catch (error) {
              setOpenOrderLoading(false);
              return toastr.error("Something went wrong!");
            }
          } else {
            setOpenOrderLoading(false);
          }
        };

        dispatch(
          getTokenCurrentPrice(
            props?.tradeTokenDetails.bitFinexSymbol,
            callBack
          )
        );
      } else {
        setOpenOrderLoading(false);
        return toastr.error("Something went wrong!");
      }
    } catch (error) {
      setOpenOrderLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  return (
    <>
      <Transacsubmit
        show={Transacsubmitmmod}
        onhide={() => setTransacsubmitmmod(false)}
      />
      <section className="buycelltab">
        <div
          className="buybtn_wrapping p-1 bg-dark w-100 rounded d-flex align-items-center 
           justify-content-between"
        >
          <Button
            className={tab == "long" && "active"}
            onClick={() => setTab("long")}
          >
            Long OLE
          </Button>
          <Button
            className={tab == "short" && "active"}
            onClick={() => setTab("short")}
          >
            Short OLE
          </Button>
        </div>
        <Form className="position-relative" onSubmit={handleSubmit}>
          <div className="price-input_wrap bottom_dropdown">
            <div className="right_dropdown w-100 d-flex align-items-center justify-content-between">
              <div className="left_chainn">
                <div className="ac_input">
                  <input
                    type="number"
                    placeholder="0"
                    min={0}
                    name="quantity"
                    value={fields.quantity}
                    onChange={handleChange("quantity")}
                  />
                </div>
                <div className="ac_token d-flex align-items-center">
                  <p className="m-0 text-white">
                    {props?.tradeTokenDetails?.symbol}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="poolinfo_here">
            <ul>
              <li>
                <p>Current Price</p>
                <p>{Number(currentTokenPrice)}</p>
              </li>
            </ul>
          </div>
          <div className="custom_range">
            <Slider
              marks={LEVERAGE_MARKS}
              name="leverage"
              trackBg="f00"
              defaultValue={1}
              min={1}
              max={5}
              onChange={handleLeverage}
              value={leverage}
            />
            <div className="poolinfo_here p-3 rounded bg-dark">
              <ul>
                <li>
                  <p>Collateral</p>
                  <p>Balance: {parseFloat(Number(balance).toFixed(4))} Eth</p>
                </li>
                <li>
                  <div className="ac_input">
                    <input
                      type="number"
                      readOnly
                      placeholder="0"
                      value={collateral}
                      min={0}
                      name="amount"
                    />
                  </div>
                  <div className="ac_token d-flex align-items-center">
                    <div className="ac_img me-1">
                      <img
                        src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p>ETH</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="poolinfo_here">
            <ul>
              {/* <li>
                <p>Asset used as Collateral</p>
                <p>9.254151</p>
              </li>
              <li>
                <p>Value of Collateral asset</p>
                <p>1,050.00</p>
              </li> */}
              <li>
                <p>Leverage used</p>
                <p>{Number(fields?.quantity) * Number(leverage)}</p>
              </li>
              {/* <li>
                <p>Entry price in Trade</p>
                <p>195421</p>
              </li> */}
            </ul>
            <div className="poolinfo_here p-3 rounded bg-dark">
              <ul>
                <li>
                  <p>Stop Loss</p>
                  <div className="ac_input stop_Loss">
                    <input
                      type="text"
                      placeholder="0"
                      value={fields?.stopLoss || 0}
                      // min={0}
                      // max={Number(settings?.stopLoss)}
                      onChange={handleChange("stopLoss")}
                      name="stopLoss"
                    // onKeyUp={(e) => { if (e.target.value > Number(settings?.stopLoss)) { toastr.error(`Enter value should be less then or equal to ${Number(settings?.stopLoss)}`); e.target.value = null; } }}
                    />
                  </div>
                </li>
              </ul>
            </div>

            <div className="buyselll_btn">
              <Button
                className="connect_wallet_cta w-100"
                onSubmit={handleSubmit}
                disabled={openOrderLoading}
              >
                {openOrderLoading ? (<div className="d-flex justify-content-center">
                  {openOrderLoading && <Spinner style={{ color: "#fff" }} />}
                </div>) : "Open Position"}

              </Button>
            </div>
          </div>

          <div className="buyselll_btn"></div>
        </Form>
      </section>
    </>
  );
};

export default Buycelltab;
