import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import CustomPagination from "Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderboard } from "store/actions";

const Position = () => {
  const { leaderboard, leaderboardCount } = useSelector(
    ({ Order }) => Order
  );
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1
  });

  //get leaderboard data
  useEffect(() => {
    dispatch(getLeaderboard({ ...filters }));
  }, [filters]);

  //pagination
  const onPageChange = (page) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  const totalPages = Math.ceil(leaderboardCount / filters?.limit);

  return (
    <section className="trade_new my-3">
      <Container fluid>
        <div className="shared_head text-start mb-3 mt-3">
          <h3 className="card_heading">Leaderboard</h3>
        </div>
        <section className="Opendertable-table pairprice_chart mt-3">
          <div className="pairshared_table">
            <Table responsive>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {(leaderboard || []).map((item) => {
                  return (
                    <tr key={item?._id} class={`${item.status == "open" && "open-position"}`}>
                      <td>{item?.userId?.username}</td>
                      <td>{item?.userId?.email}</td>
                      <td>{item?.userId?.countryCode} {item?.userId?.mobileNumber}</td>
                      <td className={`${Number(item?.PNL) > 0 ? "income-positive" : "income-negative"}`}>{parseFloat(Number(item?.PNL).toFixed(8))}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <CustomPagination
                currentPage={filters?.page}
                totalPages={totalPages}
                onPageChange={(page) => onPageChange(page)}
                maxPagesToShow={5}
              />
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default Position;
