import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Trdeconfirmmodal from "Component/Modals/Trdeconfirmmodal";
import Hotpairs from "../Hotpairs";
import Tradepair from "./Tradepair";
import Pairpricechart from "./Pairpricechart";
import { useDispatch, useSelector } from "react-redux";
import { getTradeTokenRequest } from "store/actions";

const Tradenew = () => {
  const dispatch = useDispatch();
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    dispatch(getTradeTokenRequest(filters));
  }, [filters]);
  
  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Hotpairs tradeTokens = {tradeTokens} />
        <Tradepair heading="Trade Pairs"/>
        <Pairpricechart heading="Pair Price Chart"/>
      </Container>
    </section>
  );
};

export default Tradenew;
