import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Profilesidebar from "./Profilesidebar";

const Dashboardwrap = () => {
  const [sidebarHidden, setSidebarHidden] = useState(false);

  const toggleSidebar = () => {
    setSidebarHidden(!sidebarHidden);
  };
  return (
    <section className={`profile_main ${sidebarHidden ? "sidebar-hidden" : ""}`}>
      <div className="profile_sidebar">

        <Profilesidebar toggleSidebar={toggleSidebar} />
      </div>

      <div className="profile_right_content position-relative">
        <Button onClick={toggleSidebar} className="mobile_toggle_btn">
          {!sidebarHidden ?
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 6h14M5 12h14M5 18h14" /></svg>
            </>
            :
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" /></svg>
            </>
          }
        </Button>
        <Outlet />
      </div>
    </section>
  );
};

export default Dashboardwrap;
