import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { socket } from "socket";

// importing all the themes
import Hometheme from "../themes/home-theme";
import Signup from "themes/signup-theme";
import Signintheme from "themes/signin-theme";
// import Kyctheme from "themes/kyc-theme";
import OTPtheme from "themes/otp-theme";
import Dashboardtheme from "themes/myprofile-theme";
import Verifyfeildtheme from "themes/verifyfeild-theme";
import ForgetPassword from "Pages/ForgetPassword";

// profile routes
import Myprofile from "themes/myprofile-theme";
import Dashboardpagetheme from "themes/dashboard-theme";
import Liquditytheme from "themes/liqudity-theme";
import Wallettheme from "themes/wallettheme";
import Tradetheme from "themes/trade-theme";
import Tradelistingtheme from "themes/tradelisting-theme";
import Leveragetradetheme from "themes/leveragetrade-theme";
import Tradeexpertstheme from "themes/tradeexperts-theme";
import Expertsprofiletheme from "themes/expertsprofile-theme";
import Buyplatformtheme from "themes/buyplatform-theme";
import Blogtheme from "themes/blog-theme";
import Tradelistileverage from "themes/tradelistileverage-theme";
import Buyselltheme from "themes/buysell-theme";
import Stakingtheme from "themes/staking-theme";
import Settingtheme from "themes/setting-theme";
// import OTCtradetheme from "themes/OTCtrade-theme";
// import Accounttheme from "themes/accounttheme";
// import Pricealertheme from "themes/pricealertheme";
// import Privacytheme from "themes/privacy-theme";
import Pagenotfound from "Pages/404page";
import { PrivateRoute } from "Component/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, getSettings } from "store/actions";
import OTCtradetheme from "themes/OTCtrade-theme";
import PositionTheme from "themes/positiontheme";
import Bottheme from "themes/bot-theme";
import Leaderboardtheme from "themes/leaderboardtheme";

import toastr from "toastr";

const AuthPages = ({ token, children }) => {
  if (token) {
    return <Navigate to={"/dashboard"} />;
  }
  return children;
};

const MyRouts = () => {
  const { user } = useSelector(({ Login }) => Login);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1,
    user: user?._id,
  });

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  //Listen socket on auto open, close(on stop loss and trigger price) order
  useEffect(() => {
    if (user) {
      socket.on("connect", (res) => {
        console.log("yes it connected in position", socket.id);
        socket.emit("manageStableConnection", { userId: user?._id, socketId: socket.id });
      });
      socket.on("OpenScheduledOrder", (res) => {
        console.log("Open Scheduled Order Successfully");
        dispatch(getOrders({ ...filters }));
        toastr.success("Open Scheduled Order Successfully!");
      });
      socket.on("CloseOnStopLoss", (res) => {
        console.log("Order closed successfully. Stop Loss hit!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Stop Loss hit!");
      });
      socket.on("CloseOnStopLossByAdmin", (res) => {
        console.log("Order closed successfully. Stop Loss goes 90%!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Stop Loss goes 90%!");
      });
      socket.on("CloseOnTriggerPrice", (res) => {
        console.log("Order closed successfully. Target hit!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Target hit!");
      });
      socket.on('user-disconnected', (e) => {
        console.log('User Disconnected', e);
        socket.connect();
      });
      socket.on('disconnect', (e) => {
        console.log('Disconnected', e);
        socket.connect();
      });
    }

  
  }, [socket, user]);


  useEffect(() => {
    return () => {
      socket && socket.on('disconnect', (e) => {
        console.log('Disconnected', e);
      });
    };
  }, [])
  

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <AuthPages token={user?.Token}>
                <Hometheme />
              </AuthPages>
            }
          />
          <Route
            path="/sign-up"
            element={
              <AuthPages token={user?.Token}>
                <Signup />
              </AuthPages>
            }
          />
          <Route
            path="/sign-in"
            element={
              <AuthPages token={user?.Token}>
                <Signintheme />
              </AuthPages>
            }
          />
          
          {/* <Route path="/kyc" element={<Kyctheme />} /> */}
          <Route path="/otp" element={<OTPtheme />} />
          <Route path="/otp-verify" element={<Verifyfeildtheme />} />
          <Route
            path="/forget-password"
            element={
              <AuthPages token={user?.Token}>
                <ForgetPassword />
              </AuthPages>
            }
          />
          <Route element={<Dashboardtheme />}>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboardpagetheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <PrivateRoute>
                  <Wallettheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/trade"
              element={
                <PrivateRoute>
                  <Tradetheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/trade-listing"
              element={
                <PrivateRoute>
                  <Tradelistingtheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/liquidity-pool"
              element={
                <PrivateRoute>
                  <Liquditytheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/leverage-trade"
              element={
                <PrivateRoute>
                  <Leveragetradetheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/bot"
              element={
                // <PrivateRoute>
                <Bottheme />
                // </PrivateRoute>
              }
            />
            <Route
              path="/trading-experts"
              element={
                // <PrivateRoute>
                <Tradeexpertstheme />
                // </PrivateRoute>
              }
            />

            <Route
              path="/expert-profile/:id"
              element={
                // <PrivateRoute>
                <Expertsprofiletheme />
                // </PrivateRoute>
              }
            />

            <Route
              path="/buy-platform"
              element={
                // <PrivateRoute>
                <Buyplatformtheme />
                // </PrivateRoute>
              }
            />
            <Route
              path="/buy-token"
              element={
                // <PrivateRoute>
                <Buyselltheme />
                // </PrivateRoute>
              }
            />

            <Route
              path="/blog"
              element={
                // <PrivateRoute>
                <Blogtheme />
                // </PrivateRoute>
              }
            />
            <Route
              path="/leverage/:id"
              element={
                <PrivateRoute>
                  <Tradelistileverage />
                </PrivateRoute>
              }
            />
            <Route
              path="/staking"
              element={
                <PrivateRoute>
                  <Stakingtheme />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Settingtheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/positions"
              element={
                <PrivateRoute>
                  <PositionTheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute>
                  <Leaderboardtheme />
                </PrivateRoute>
              }
            />

            <Route path="/otc-trade" element={<OTCtradetheme />} />
            {/* <Route path="/myaccount" element={<Accounttheme />} />
              <Route path="/price-alert" element={<Pricealertheme />} />

              <Route path="/privacy-policy" element={<Privacytheme />} /> */}
          </Route>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default MyRouts;
